import React, { useContext, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import listStyle from "./PatientList.module.css";
import PatientsTable from "../../Components/PatientsTable/PatientsTable";
import { GetAllUsers, GetAssignedUsers } from "../../Services/GetUsers";
import { AuthContext } from "../../context/AuthContext";
import { Redirect, useLocation } from "react-router-dom";
import { Button } from "@mui/material";

const subStagesScanBooked = [
  "Scan Booked",
  "Scan booked but not done",
  "Not interested",
  "Scan Done",
  "Scan Done at 49k",
  "Scan Done at 69k",
];

const subStagesPrepareTP = [
  "Prepare TP",
  "Prepare TP-Priority",
  "Need more info",
  "On Hold",
  "TP Shared",
  "Rejected",
];

const subStagesTPReview = [
  "Pending",
  "Accepted",
  "On Hold",
  "Revision Plan",
  "Rejected",
];

const subStagesTPOut = [
  "TP Consultation",
  "Revise Plan",
  "Not Interested",
  "Interested Later",
  "Negotiation",
  "Price Too High",
  "Lost to competition",
  "Plan not good",
];

const subStagesInTreatment = [
  "Ongoing",
  "Review Progress",
  "Remove Attachment",
];

const subStagesResolutionRequired = [
  "Case Review",
  "Pending Scan",
  "Treatment Planning",
  "Review TP",
  "TP Consultation",
  "Manufacturing",
  "Delivery",
  "Resolved",
  "Rejected",
];

const subStagesLost = ["Lost", "Competition", "Pricing", "Treatment Plan"];

const stageSubStageMapping = {
  "Scan Booked": subStagesScanBooked,
  "TP in Progress": subStagesPrepareTP,
  "Review TP": subStagesTPReview,
  "TP Approved": subStagesTPOut,
  "In Treatment": subStagesInTreatment,
  "Resolution Required": subStagesResolutionRequired,
  Lost: subStagesLost,
};

function PatientList({ name, data, totalUserCount, onUserDataRequest }) {
  const [search, setSearch] = useState("");
  // console.log(data);

  const [usersList, setUsersList] = useState([]);
  const { isAuthenticated, setIsAuthenticated, user } = useContext(AuthContext);
  const [userPaginationData, setUserPaginationData] = useState({
    limit: 15,
    offset: 0,
    stage: name,
    nextToken:""
  });
  const [nextPageToken,setNextPageToken] = useState("");
  const [currentSubStage, setCurrentSubStage] = useState(
    stageSubStageMapping[name] !== undefined
      ? stageSubStageMapping[name][0]
      : "All Sub Stages"
  );
  const [shouldSortByScanDate, setShouldSortByScanDate] = useState("yes");
  console.log();
  const [sortingOrder, setSortingOrder] = useState(
    currentSubStage === "Scan Booked" || name === "doctor-consultation"
      ? "descending"
      : "ascending"
  );
  const [shouldUpdateUserList, setShouldUpdateUserList] = useState(false);
  const [shouldRedirectForScanningDoc, setShouldRedirectForScanningDoc] =
    useState(user?.admin_user_type === "scanning_doctor" ? true : false);
  const [shouldRedirectForSales, setShouldRedirectForSales] = useState(
    user?.admin_user_type === "sales" ? true : false
  );

  const [startDateScanTimeFilter, setStartDateScanTimeFilter] = useState("");
  const [EndDateScanTimeFilter, setEndDateScanTimeFilter] = useState("");

  useEffect(() => {
    setCurrentSubStage(
      stageSubStageMapping[name] !== undefined
        ? stageSubStageMapping[name][0]
        : "All Sub Stages"
    );
  }, [name]);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const onLoadMore = () => {
    setIsLoadingData(true);
    setUserPaginationData((prev) => {
      const newData = { ...prev };
      newData.offset = usersList.length;
      newData.nextToken = nextPageToken;
      return newData;
    });
  };

  const onSortingOrderToggle = (order = "") => {
    if (order === "") {
      setSortingOrder((prev) =>
        prev === "ascending" ? "descending" : "ascending"
      );
      setShouldSortByScanDate("no");
    } else {
      setSortingOrder(order);
      setShouldSortByScanDate("yes");
    }
    setUsersList([]);
    setShouldUpdateUserList(true);
    setUserPaginationData((prev) => {
      const newData = { ...prev };
      newData.offset = 0;
      newData.nextToken = "";
      return newData;
    });
  };

  const onScanDateSortingOrderToggle = () => {
    setSortingOrder((prev) =>
      prev === "ascending" ? "descending" : "ascending"
    );
    setShouldSortByScanDate("yes");
    setUsersList([]);
    setShouldUpdateUserList(true);
    setUserPaginationData((prev) => {
      const newData = { ...prev };
      newData.offset = 0;
      newData.nextToken = "";
      return newData;
    });
  };

  const onSearchFieldChange = (val) => {
    setSearch(val);
    setUsersList([]);
    setShouldUpdateUserList(true);
  };

  const onSubStageChange = (subStage) => {
    console.log(subStage);
    setUsersList([]);
    setShouldUpdateUserList(true);
    if (subStage === "Scan Booked") {
      console.log(subStage);
      onSortingOrderToggle("descending");
    } else {
      currentSubStage === "Scan Booked" &&
        sortingOrder === "descending" &&
        onSortingOrderToggle();
    }
    setCurrentSubStage(subStage);
  };

  const onScanFilterApply = (start = "", end = "") => {
    console.log("start", start);
    console.log("end", end);
    setStartDateScanTimeFilter((prev) => {
      if (prev !== start) {
        setUsersList([]);
        setShouldUpdateUserList(true);    
      }
      return start;
    });
    setEndDateScanTimeFilter((prev) => {
      if (prev !== end) {
        setUsersList([]);
        setShouldUpdateUserList(true);    
      }
      console.log("enddd",end);
      return end;
    });
  };

  useEffect(() => {
    setIsLoadingData(true);
    console.log(currentSubStage === "All Sub Stages" ? "" : currentSubStage);
    fetchUsers(currentSubStage === "All Sub Stages" ? "" : currentSubStage);
  }, [currentSubStage]);

  useEffect(() => {
    setIsLoadingData(true);
    fetchUsers();
  }, [startDateScanTimeFilter, EndDateScanTimeFilter]);

  const fetchUsers = (subStage = "") => {
    console.log(subStage);
    console.log(shouldUpdateUserList);
    if (isAuthenticated && shouldUpdateUserList) {
      console.log(userPaginationData);
      if (user?.admin_user_type === "admin") {
        const isSearchingByPhone = !Number.isNaN(Number(search));
        const searchValueWhenRequested = search;
        GetAllUsers({
          ...userPaginationData,
          sortBy: sortingOrder,
          search: !isSearchingByPhone && search ? search : "",
          searchPhone: isSearchingByPhone && search ? search : "",
          subStage: subStage,
          scanTimeFilter: {
            start: startDateScanTimeFilter,
            end: EndDateScanTimeFilter,
          },
        }).then((data) => {
          setNextPageToken(data.data.nextToken);
          if (data.data.nextToken === "") setShouldUpdateUserList(false);
          else setShouldUpdateUserList(true);
          searchValueWhenRequested === search &&
            setUsersList(
              (prev) =>
                // sortingOrder === "ascending"
                prev.concat(data.data.data)
              // : data.data.data.concat(prev)
            );
          setIsLoadingData(false);
        });
      } else {
        console.log("role");
        console.log(subStage);
        const isSearchingByPhone = !Number.isNaN(Number(search));
        const searchValueWhenRequested = search;
        GetAssignedUsers({
          ...userPaginationData,
          sortBy: sortingOrder,
          sortScanTime: shouldSortByScanDate === "yes" ? "ScanTime" : "",
          search: !isSearchingByPhone && search ? search : "",
          searchPhone: isSearchingByPhone && search ? search : "",
          subStage: subStage,
          scanTimeFilter: {
            start: startDateScanTimeFilter,
            end: EndDateScanTimeFilter,
          },
        }).then((data) => {
          if (data.data.nextToken === "") setShouldUpdateUserList(false);
          else setShouldUpdateUserList(true);
          searchValueWhenRequested === search &&
            setUsersList(
              (prev) =>
                // sortingOrder === "ascending"
                prev.concat(data.data.data)
              // : data.data.data.concat(prev)
            );
          setIsLoadingData(false);
        });
      }
    }
  };

  useEffect(() => {
    setUserPaginationData((prev) => {
      const newData = { ...prev };
      newData.stage = name;
      newData.offset = 0;
      newData.nextToken = "";
      return newData;
    });
    setUsersList([]);
    setShouldUpdateUserList(true);
  }, [name]);

  useEffect(() => {
    const controller = new AbortController();
    if (isAuthenticated && shouldUpdateUserList) {
      console.log(userPaginationData);
      if (user?.admin_user_type === "admin") {
        const isSearchingByPhone = !Number.isNaN(Number(search));
        const searchValueWhenRequested = search;
        setIsLoadingData(true);
        GetAllUsers(
          {
            ...userPaginationData,
            sortBy: sortingOrder,
            search: !isSearchingByPhone && search ? search : "",
            searchPhone: isSearchingByPhone && search ? search : "",
            subStage:
              currentSubStage === "All Sub Stages" ? "" : currentSubStage,
            sortScanTime: shouldSortByScanDate === "yes" ? "ScanTime" : "",
            scanTimeFilter: {
              start: startDateScanTimeFilter,
              end: EndDateScanTimeFilter,
            },
          },
          controller.signal
        ).then((data) => {
          setNextPageToken(data.data.nextToken);
          if (data.data.nextToken === "") setShouldUpdateUserList(false);
          else setShouldUpdateUserList(true);
          searchValueWhenRequested === search &&
            setUsersList(
              (prev) =>
                // sortingOrder === "ascending"
                prev.concat(data.data.data)
              // : data.data.data.concat(prev)
            );
          console.log("bvd")
          setIsLoadingData(false);
        });
      } else {
        console.log("role");
        const isSearchingByPhone = !Number.isNaN(Number(search));
        const searchValueWhenRequested = search;
        GetAssignedUsers(
          {
            ...userPaginationData,
            sortBy: sortingOrder,
            sortScanTime: shouldSortByScanDate === "yes" ? "ScanTime" : "",
            search: !isSearchingByPhone && search ? search : "",
            searchPhone: isSearchingByPhone && search ? search : "",
            subStage:
              currentSubStage === "All Sub Stages" ? "" : currentSubStage,
            scanTimeFilter: {
              start: startDateScanTimeFilter,
              end: EndDateScanTimeFilter,
            },
          },
          controller.signal
        ).then((data) => {
          if (data.data.end === true) setShouldUpdateUserList(false);
          else setShouldUpdateUserList(true);
          searchValueWhenRequested === search &&
            setUsersList(
              (prev) =>
                // sortingOrder === "ascending"
                prev.concat(data.data.data)
              // : data.data.data.concat(prev)
            );
          setIsLoadingData(false);
        });
      }
    }
    return () => {
      controller.abort();
    };
  }, [isAuthenticated, userPaginationData]);

  useEffect(() => {
    const debounceTimerId = setTimeout(() => {
      setIsLoadingData(true);
      fetchUsers(currentSubStage === "All Sub Stages" ? "" : currentSubStage);
    }, 1000);
    return () => {
      clearTimeout(debounceTimerId);
    };
  }, [search]);

  useEffect(() => {
    console.log(user, shouldRedirectForScanningDoc);
    setShouldRedirectForScanningDoc(false);
    console.log(user, shouldRedirectForSales);
    setShouldRedirectForSales(false);
  }, []);

  // useEffect(() => {
  //   fetchUsers();
  // }, [sortingOrder]);

  if (shouldRedirectForScanningDoc) {
    return <Redirect to="/scan-booked" />;
  }

  if (shouldRedirectForSales) {
    return <Redirect to="/doctor-consultation" />;
  }

  return (
    <div className={listStyle.container}>
      <Header
        status={name}
        searchVal={search}
        setSearch={onSearchFieldChange}
        newPatient
        onSubStageChange={onSubStageChange}
        onScanFilterApply={onScanFilterApply}
        currentSubStage={currentSubStage}
        setCurrentSubStage={setCurrentSubStage}
      />
      <PatientsTable
        data={usersList}
        searchVal={""}
        order={sortingOrder}
        onSortingOrderToggle={onSortingOrderToggle}
        onScanDateSortingOrderToggle={onScanDateSortingOrderToggle}
        subStage={currentSubStage}
        stage={name}
      />
      {isLoadingData ? (
        <div style={{ display: `flex`, justifyContent: `center` }}>
          <img src="/Circle-Loading.svg" alt="loader" height={75} width={75} />
        </div>
      ) : (
        <div
          // ref={ref}
          style={{
            display: `flex`,
            justifyContent: `center`,
            padding: `1rem`,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              onLoadMore();
            }}
            disabled={!shouldUpdateUserList}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
}

export default PatientList;
