import request from "./API";

const ChangeStage = (data) => {
  return request.post("/admin-form/user/stage", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const NewTreatmentPlan = (data) => {
  return request.post("/users/treatment", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const AddFields = (data) => {
  return request.post("/admin-form/treatment/update", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const ChangeCurrPlan = (data) => {
  return request.post("/users/treatment/change", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const UploadFile = (data) => {
  return request.post("/admin-form/treatment/three-d/add", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const DeleteFile = (data) => {
  return request.delete("/admin-form/treatment/delete", {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};

const GetUserType = (user) => {
  return request.get(`/admin-form/users/${user}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const GetPaymentDetails = (userId) => {
  return request.get(`/admin-form/payments/${userId}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const UpdateCurrentTPBase = (data) => {
  return request.post(`/users/treatment-price/base-amount`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const UpdateAlreadyCollectedAmount = (data) => {
  return request.post(`/users/treatment-price/already-collected`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const AddDiscountCode = (data) => {
  return request.post(`/admin-form/treatment-price/discount-code`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const RemoveDiscountCode = (data) => {
  return request.post(
    `/admin-form/treatment-price/discount-code/remove`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const AddPayment = (data) => {
  return request.post(`/admin-form/manual-payment`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const CreatePatient = (data) => {
  return request.post(`/admin-form/patient/new`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const CreateB2BPatient = (data) => {
  return request.post(`/admin-form/patient-b2b/new`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export {
  ChangeStage,
  NewTreatmentPlan,
  AddFields,
  ChangeCurrPlan,
  UploadFile,
  DeleteFile,
  GetUserType,
  GetPaymentDetails,
  CreatePatient,
  CreateB2BPatient,
  UpdateCurrentTPBase,
  UpdateAlreadyCollectedAmount,
  AddDiscountCode,
  RemoveDiscountCode,
  AddPayment,
};
