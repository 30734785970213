import { Button, FormControl, Input, NativeSelect } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ChangeFeedback } from "../../Services/FeedbackService";
import { GetUser } from "../../Services/GetUsers";

import styles from "./ReviewScan.module.css"

const ReviewScan = ({ patient, onUserUpdateHandler }) => {
  const availableScans = patient?.progress_scan?.progress_scan_steps;
  const [selectedScan, setSelectedScan] = useState(
    availableScans?.length > 0 ? 0 : null
  ); //indexed 0 but send with indexing 1
  const [verdict, setVerdict] = useState(
    availableScans?.[0].doctor_feedback.verdict
  );
  const [comment, setComment] = useState(
    availableScans?.[0].doctor_feedback.comments
  );

  const onVerdictChange = (value) => {
    setVerdict(value);
  };
  const onCommentChange = (value) => {
    setComment(value);
  };

  const onFeedbackSubmit = () => {
    // console.log(verdict,comment);
    //send request
    ChangeFeedback(patient?.user_id, selectedScan + 1, {
      verdict,
      comment,
    }).then((data) => {
      GetUser({ user_id: patient?.user_id }).then((res) =>
        onUserUpdateHandler(res.data)
      );
    });
  };

  console.log(selectedScan);

  useEffect(() => {
    setVerdict(availableScans && availableScans[selectedScan]?.doctor_feedback.verdict);
    setComment(availableScans && availableScans[selectedScan]?.doctor_feedback.comments);
  }, [selectedScan, availableScans]);
  return (
    <div>
      {selectedScan ? (
        <div
          style={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <FormControl>
            <NativeSelect
              onChange={(e) => setSelectedScan(e.target.value)}
              defaultValue={availableScans.length > 0 ? 0 : "no-scans"}
              value={selectedScan}
              inputProps={{
                name: "select step",
                id: "uncontrolled-native",
              }}
            >
              {availableScans?.length === 0 && (
                <option value="no-scans">No Scans Yet</option>
              )}
              {availableScans?.map((scan, index) => (
                <option value={index}>{`Scan number ${index + 1}`}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
      ) : (
        <h2>No Scans Yet</h2>
      )}
      {selectedScan && (
        <div style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}>
          <div>
            <h3>Progress Images</h3>
            <div
              // style={{
              //   display: `grid`,
              //   gridTemplateColumns: `1fr 1fr`,
              //   gap: `1rem`,
              // }}
              className={styles.imageContainer}
            >
              {availableScans[selectedScan]?.progress_images.map((imageObj) => {
                return (
                  imageObj.image_url && (
                    <img
                      src={imageObj.image_url}
                      alt={imageObj?.type || "progress-image"}
                      style={{
                        width: `400px`,
                        height: `400px`,
                        objectFit: `contain`,
                      }}
                    />
                  )
                );
              })}
            </div>
          </div>
          <div
            style={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
              alignItems: `center`,
              border: `2px solid #1976D5`,
              borderRadius: `10px`,
              padding: `20px`,
              flexWrap: `wrap`,
            }}
          >
            <div
              style={{
                display: `flex`,
                flexDirection: `row`,
                //   justifyContent: `space-between`,
                gap: `2rem`,
                alignItems: `center`,
              }}
            >
              <b>Verdict:</b>
              {/* <Input
                value={verdict}
                onChange={onVerdictChange}
                buttonsRequired={false}
              /> */}
              <FormControl>
                <NativeSelect
                  onChange={(e) => onVerdictChange(e.target.value)}
                  // defaultValue={availableScans?.[0].aligner_step || "no-scans"}
                  value={verdict}
                  inputProps={{
                    name: "select step",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value="" disabled selected>
                    Select your verdict
                  </option>

                  <option value="yes">{`Accepted`}</option>
                  <option value="no">{`Rejected`}</option>
                </NativeSelect>
              </FormControl>
            </div>
            <div
              style={{
                display: `flex`,
                flexDirection: `row`,
                //   justifyContent: `space-between`,
                gap: `1rem`,
                alignItems: `center`,
              }}
            >
              <b>Comment:</b>
              <Input
                value={comment}
                multiple
                onChange={onCommentChange}
                buttonsRequired={false}
                multiline
                // style={{ width: `300px` }}
              />
            </div>
            <Button
              variant="contained"
              onClick={onFeedbackSubmit}
              style={{
                height: `max-content`,
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewScan;
