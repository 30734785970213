import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  NativeSelect,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import React, { createRef, useEffect, useState } from "react";
import { GetUser } from "../../Services/GetUsers";
import {
  AddFields,
  DeleteFile,
  UploadFile,
} from "../../Services/TreatmentService";
import FileUpload from "../FileUpload/FileUpload";
import Input from "../Input/Input.jsx";
import { PhotoUploadField } from "../PhotoUploadField/PhotoUploadField";
import { SingleSTLUploadField } from "../SingleSTLUploadField/SingleSTLUploadField";
import styles from "./TreatmentSubForm.module.css";

function TreatmentSubForm({
  selectedPlan,
  setSelectedPlan,
  id,
  patient,
  doctors,
  salesPerson,
  assignedDoctor,
  assignedSalesPerson,
  setAssignedDoctor,
  setAssignedSalesPerson,
  setPatient,
}) {
  const imgRef = createRef(null);
  const [IprUrl, setIprUrl] = useState({});
  const [IPR, setIPR] = useState("");
  const [attachments, setAttachments] = useState("");
  const [notes, setNotes] = useState("");
  const [transformationUrl, setTransformationUrl] = useState("");
  const [feedback, setFeedback] = useState("");
  const [numUpper, setNumUpper] = useState();
  const [numLower, setNumLower] = useState();
  const [attachment, setAttachment] = useState();
  const [isArchformDialogOpen, setIsArchformDialogOpen] = useState(false);
  const [archformSettingsData, setArchformSettingsData] = useState({
    distance: {},
    rotation: {},
  });
  const [isIprDialogOpen, setIsIprDialogOpen] = useState(false);
  const [iprChanged, setIprChanged] = useState(false);
  const [isAttachmentDialogOpen, setIsAttachmentDialogOpen] = useState(false);
  const [isLowerUploaded, setIsLowerUploaded] = useState(true);
  const [isUpperUploaded, setIsUpperUploaded] = useState(true);

  const archformFields = {
    distance: [
      { fieldName: `left_right`, fieldTitle: `Left/Right` },
      { fieldName: `in_out`, fieldTitle: `In/Out` },
      { fieldName: `down`, fieldTitle: `Down` },
      { fieldName: `up_intrusion`, fieldTitle: `Up/Intrusion` },
    ],
    rotation: [
      { fieldName: `root_tip`, fieldTitle: `Root Tip` },
      { fieldName: `torque`, fieldTitle: `Torque` },
      { fieldName: `rotation`, fieldTitle: `Rotation` },
    ],
  };

  const upperTeeth = [
    18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28,
  ];
  const lowerTeeth = [
    48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38,
  ];

  const iprFields = {
    upper: upperTeeth
      .map((v, i) => {
        if (i === upperTeeth.length - 1) return "";
        if (upperTeeth[i + 1] < upperTeeth[i])
          return `${upperTeeth[i + 1]}-${upperTeeth[i]}`;
        else {
          return `${upperTeeth[i]}-${upperTeeth[i + 1]}`;
        }
      })
      .filter((v) => v.length !== 0),
    lower: lowerTeeth
      .map((v, i) => {
        if (i === lowerTeeth.length - 1) return "";
        if (lowerTeeth[i + 1] < lowerTeeth[i])
          return `${lowerTeeth[i + 1]}-${lowerTeeth[i]}`;
        else {
          return `${lowerTeeth[i]}-${lowerTeeth[i + 1]}`;
        }
      })
      .filter((v) => v.length !== 0),
  };

  useEffect(() => {
    if (selectedPlan) {
      console.log(selectedPlan);
      setIprUrl({ url: selectedPlan?.ipr_image?.url });
      setTransformationUrl(selectedPlan?.transformation_url);
      setFeedback(selectedPlan?.doctor_feedback?.comments);
      setNumUpper(selectedPlan?.treatment_info?.num_aligners_upper);
      setNumLower(selectedPlan?.treatment_info?.num_aligners_lower);
      setAttachment(selectedPlan?.treatment_info?.attachment_needed);
      selectedPlan?.archform_settings &&
        setArchformSettingsData(
          selectedPlan?.archform_settings
        );
      let notes = "";
      selectedPlan?.treatment_info?.treatment_notes?.forEach((note) => {
        notes += note + "\n";
      });
      let ipr = "";
      let last = "";
      for (let key in selectedPlan?.ipr_spec_files) {
        last = key;
      }
      for (let key in selectedPlan?.ipr_spec_files) {
        if (key == last) ipr += key + ":" + selectedPlan?.ipr_spec_files[key];
        else ipr += key + ":" + selectedPlan?.ipr_spec_files[key] + "\n";
      }

      setIPR(ipr);
      setNotes(notes);
    }
  }, [selectedPlan]);
  const changeField = (userId, keyId, value, multiline) => {
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    if (keyId === "upper_aligner_no" || keyId === "lower_aligner_no") {
      value = parseInt(value);
    }
    keyId === "ipr_spec" && value === ""
      ? AddFields({
          user_id: userId,
          modified_time: createdTime + " " + time,
          type: keyId,
          [keyId]: [],
        })
          .then((res) => {
            setIprUrl("");
            setIprUrl(res.data.ipr_url);
            GetUser({ user_id: userId }).then((res) => {
              const plan = res?.data?.treatment_plans?.filter(
                (plan) => plan?.plan_id === selectedPlan?.plan_id
              )[0];
              console.log(plan);
              setSelectedPlan({ ...plan });
              setPatient(res?.data);
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          })
      : AddFields({
          user_id: userId,
          modified_time: createdTime + " " + time,
          type: keyId,
          [keyId]: multiline ? value.split("\n") : value,
        })
          .then((res) => {
            GetUser({ user_id: userId }).then((res) => {
              const plan = res?.data?.treatment_plans?.filter(
                (plan) => plan?.plan_id === selectedPlan?.plan_id
              )[0];
              console.log(plan);
              setSelectedPlan({ ...plan });
              setPatient(res?.data);
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Something went wrong", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          });
  };
  const uploadRetainerFile = (e, view) => {
    view === "lower" ? setIsLowerUploaded(false) : setIsUpperUploaded(false);
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${
      d.getMonth().toString().length === 1
        ? "0" + parseInt(d.getMonth() + 1)
        : d.getMonth() + 1
    }/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    console.log(e.target.files[0]);
    let formData = new FormData();
    formData.append("User_id", patient?.user_id);
    formData.append("type", "3d_files_retainer");
    formData.append("modified_time", `${createdTime} ${time}`);
    formData.append("3d_files_retainer", e.target.files[0]);
    UploadFile(formData)
      .then((res) => {
        console.log(res.data);
        view === "lower" ? setIsLowerUploaded(true) : setIsUpperUploaded(true);
        GetUser({ user_id: patient.user_id }).then((res) => {
          const plan = res?.data?.treatment_plans?.filter(
            (plan) => plan?.plan_id === selectedPlan?.plan_id
          )[0];
          console.log(plan);
          setSelectedPlan({ ...plan });
          setPatient(res?.data);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        view === "lower" ? setIsLowerUploaded(true) : setIsUpperUploaded(true);
      });
  };
  useEffect(() => {
    console.log(assignedDoctor);
    console.log(assignedSalesPerson);
  }, [assignedDoctor, assignedSalesPerson]);
  const changeSalesPerson = (e) => {
    const value = e.target.value;
    const newSales = salesPerson?.find((person) => person.sales_id === value);
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    AddFields({
      user_id: patient?.user_id,
      modified_time: `${createdTime} ${time}`,
      type: "sales_id",
      sales_id: value,
    })
      .then((res) => {
        setAssignedSalesPerson(newSales);
        GetUser({ user_id: patient.user_id }).then((res) => {
          const plan = res?.data?.treatment_plans?.filter(
            (plan) => plan?.plan_id === selectedPlan?.plan_id
          )[0];
          setPatient(res?.data);
          setSelectedPlan({ ...plan });
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const changeDoctor = (e) => {
    const value = e.target.value;
    const newDoctor = doctors?.find((person) => person.user_id === value);
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    AddFields({
      user_id: patient?.user_id,
      modified_time: `${createdTime} ${time}`,
      type: "doctor_id",
      doctor_id: value,
    })
      .then((res) => {
        setAssignedDoctor(newDoctor);
        GetUser({ user_id: patient.user_id }).then((res) => {
          const plan = res?.data?.treatment_plans?.filter(
            (plan) => plan?.plan_id === selectedPlan?.plan_id
          )[0];
          setPatient(res?.data);
          setSelectedPlan({ ...plan });
        });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const deleteRetainer = (view) => {
    let d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const file = selectedPlan?.stl_retainer
      ?.find((file) => file.view === view)
      ?.location?.split("/");
    console.log(file);
    let fileName = file[file.length - 1];
    const formData = new FormData();
    formData.append("User_id", patient?.user_id);
    formData.append("type", "3d_files_retainer");
    formData.append("modified_time", `${createdTime} ${time}`);
    formData.append("stage", "Treatment");
    formData.append("filename", fileName);
    DeleteFile(formData)
      .then((res) => {
        console.log(res);
        GetUser({ user_id: patient.user_id }).then((res) => {
          const plan = res?.data?.treatment_plans?.filter(
            (plan) => plan?.plan_id === selectedPlan?.plan_id
          )[0];
          setSelectedPlan(plan);
          setPatient(res?.data);
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onArchformFieldChangeHandler = (type, field, value) => {
    // console.log(archformSettingsData);
    setArchformSettingsData((prev) => {
      // console.log(prev, JSON.stringify(prev));
      const currData = JSON.parse(JSON.stringify(prev));
      currData[type][field] = Number(value);
      console.log(currData);
      return currData;
    });
  };

  const onArchformSettingsSaveHandler = () => {
    setIsArchformDialogOpen(false);
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    AddFields({
      user_id: patient?.user_id,
      modified_time: `${createdTime} ${time}`,
      type: "archform_settings",
      archform_settings: archformSettingsData,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onIPRFieldChange = (field, value) => {
    if (value === "") return;
    setIPR((prev) =>
      prev.includes(field)
        ? prev
            .split(`\n`)
            .filter((v) => !v.includes(field))
            .join(`\n`)
            .concat(`${field}:${value}\n`)
        : prev.concat(`${field}:${value}\n`)
    );
  };

  const onIprSaveHandler = () => {
    changeField(patient?.user_id, "ipr_spec", IPR.slice(0, -1), true);
    setIsIprDialogOpen(false);
  };

  const onAttachmentFieldChange = (field, value) => {
    if (value === "") return;
    setAttachments((prev) =>
      prev.includes(field)
        ? prev
            .split(`\n`)
            .filter((v) => !v.includes(field))
            .join(`\n`)
            .concat(`${field}:${value}\n`)
        : prev.concat(`${field}:${value}\n`)
    );
  };

  const onAttachmentSaveHandler = () => {
    console.log(attachments.slice(0, -1));
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    AddFields({
      user_id: patient.user_id,
      modified_time: `${createdTime} ${time}`,
      type: "attachment_spec",
      attachment_spec: attachments.slice(0, -1).split(`\n`),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
    setIsAttachmentDialogOpen(false);
  };

  return (
    <>
      <ToastContainer />

      <div className={styles.formContainer}>
        <div className={styles.heading}>
          <h2>Current Selected Plan ({id})</h2>
        </div>
        <div className={styles.formBody}>
          <div className={styles.formInpFields}>
            <div className={styles.inputBox}>
              <b>TP_Id:</b>
              <Input label="TP_id" value={id} disabled />
            </div>
            <div className={styles.inputBox}>
              <b>Created Time:</b>
              <Input
                label="Created Time"
                value={selectedPlan?.created_time}
                disabled
              />
            </div>
            <div className={styles.inputBox}>
              <b>No. of upper:</b>

              <Input
                userId={patient?.user_id}
                value={selectedPlan?.treatment_info?.num_aligners_upper}
                keyId="upper_aligner_no"
                label="No. of upper"
                onSubmit={changeField}
                onChange={(e) => setNumUpper(e)}
                treatment
              />
            </div>
            <div className={styles.inputBox}>
              <b>No. of lower:</b>

              <Input
                userId={patient?.user_id}
                value={selectedPlan?.treatment_info?.num_aligners_lower}
                label="No. of lower"
                keyId="lower_aligner_no"
                onSubmit={changeField}
                onChange={(e) => setNumLower(e)}
                treatment
              />
            </div>
            <div className={styles.inputBox}>
              <b>IPR Sheet:</b>
              <Button
                variant="contained"
                onClick={() => {
                  setIsIprDialogOpen(true);
                }}
              >
                Open Dialog
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isIprDialogOpen}
                // onClick={() => setIsArchformDialogOpen(false)}
              >
                <div
                  style={{
                    borderRadius: `3rem`,
                    backgroundColor: `white`,
                    color: `black`,
                    padding: `2rem 3rem`,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `1rem`,
                    width: `80%`,
                  }}
                >
                  <h3>IPR</h3>
                  <div>
                    <div>
                      <b>Upper Jaw IPR</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Value</p>
                        <div style={{ display: `flex`, overflowX: `scroll` }}>
                          {iprFields.upper.map((field) => (
                            <div
                              style={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `flex-start`,
                              }}
                            >
                              <label htmlFor={field}>{field}</label>
                              <Input
                                name={field}
                                value={
                                  IPR.split(`\n`)
                                    .filter((v) => v.includes(field))[0]
                                    ?.split(`:`)?.[1]
                                }
                                buttonsRequired={false}
                                onChange={(e) => onIPRFieldChange(field, e)}
                                style={{ width: `4em` }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <b>Lower Jaw IPR</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Value</p>
                        <div style={{ display: `flex`, overflowX: `scroll` }}>
                          {iprFields.lower.map((field) => (
                            <div
                              style={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `flex-start`,
                                width: `70%`,
                              }}
                            >
                              <label htmlFor={field}>{field}</label>
                              <Input
                                name={field}
                                value={
                                  IPR.split(`\n`)
                                    .filter((v) => v.includes(field))[0]
                                    ?.split(`:`)[1]
                                }
                                buttonsRequired={false}
                                onChange={(e) => onIPRFieldChange(field, e)}
                                style={{ width: `4em` }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      gap: `1rem`,
                    }}
                  >
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="contained"
                      onClick={onIprSaveHandler}
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="outlined"
                      onClick={() => {
                        setIsIprDialogOpen(false);
                      }}
                    >
                      Dismiss
                    </Button>
                  </div>
                </div>
              </Backdrop>
              {/* <Input
              label="IPR Sheet"
              userId={patient?.user_id}
              keyId="ipr_spec"
              onSubmit={changeField}
              onChange={(e) => setIPR(e)}
              value={IPR}
              setIprUrl={setIprUrl}
              multiline
              treatment
              canBeEmpty
            /> */}
            </div>
            <div className={styles.inputBox}>
              <b>Attachment:</b>
              <Button
                variant="contained"
                sx={{ margin: `10px 0` }}
                onClick={() => {
                  setIsAttachmentDialogOpen(true);
                }}
              >
                Open Dialog
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isAttachmentDialogOpen}
                // onClick={() => setIsArchformDialogOpen(false)}
              >
                <div
                  style={{
                    borderRadius: `3rem`,
                    backgroundColor: `white`,
                    color: `black`,
                    padding: `2rem 3rem`,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `1rem`,
                    width: `80%`,
                  }}
                >
                  <h3>Attachments</h3>
                  <div>
                    <div>
                      <b>Upper Attachments</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Value</p>
                        <div style={{ display: `flex`, overflowX: `scroll` }}>
                          {upperTeeth.map((field) => (
                            <div
                              style={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `flex-start`,
                              }}
                            >
                              <label htmlFor={field}>{field}</label>
                              <Input
                                name={field}
                                value={
                                  selectedPlan?.attachment_files?.[`${field}`]
                                }
                                buttonsRequired={false}
                                onChange={(e) =>
                                  onAttachmentFieldChange(field, e)
                                }
                                style={{ width: `4em` }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      <b>Lower Attachments</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Value</p>
                        <div style={{ display: `flex`, overflowX: `scroll` }}>
                          {lowerTeeth.map((field) => (
                            <div
                              style={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `flex-start`,
                                width: `70%`,
                              }}
                            >
                              <label htmlFor={field}>{field}</label>
                              <Input
                                name={field}
                                value={
                                  selectedPlan?.attachment_files?.[`${field}`]
                                }
                                buttonsRequired={false}
                                onChange={(e) =>
                                  onAttachmentFieldChange(field, e)
                                }
                                style={{ width: `4em` }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      gap: `1rem`,
                    }}
                  >
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="contained"
                      onClick={onAttachmentSaveHandler}
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="outlined"
                      onClick={() => {
                        setIsAttachmentDialogOpen(false);
                      }}
                    >
                      Dismiss
                    </Button>
                  </div>
                </div>
              </Backdrop>
              {/* <RadioGroup
              onChange={(e) => {
                const d = new Date();
                let createdTime = `${
                  d.getDate().toString().length === 1
                    ? "0" + d.getDate()
                    : d.getDate()
                }/${d.getMonth() + 1}/${d.getFullYear()}`;
                let time = d.toLocaleString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                });
                AddFields({
                  user_id: patient.user_id,
                  modified_time: `${createdTime} ${time}`,
                  type: "attachment_needed",
                  attachment_needed: e.target.value,
                }).then((res) => {
                  setAttachment(e.target.value === "yes" ? true : false);
                });
              }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="yes"
                control={<Radio checked={attachment ? true : false} />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio checked={attachment ? false : true} />}
                label="No"
              />
            </RadioGroup> */}
            </div>
            <div className={styles.inputBox}>
              <b>Archform Settings:</b>
              <Button
                variant="contained"
                onClick={() => {
                  setIsArchformDialogOpen(true);
                }}
              >
                Open Dialog
              </Button>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isArchformDialogOpen}
                // onClick={() => setIsArchformDialogOpen(false)}
              >
                <div
                  style={{
                    borderRadius: `3rem`,
                    backgroundColor: `white`,
                    color: `black`,
                    padding: `2rem 3rem`,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: `1rem`,
                  }}
                >
                  <h3>Archform Settings</h3>
                  <div>
                    <div>
                      <b>Distance</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Distance Step Size (mm)</p>
                        {archformFields.distance.map((field) => (
                          <div
                            style={{
                              display: `flex`,
                              flexDirection: `column`,
                              justifyContent: `flex-start`,
                            }}
                          >
                            <label htmlFor={field.fieldName}>
                              {field.fieldTitle}
                            </label>
                            <Input
                              name={field.fieldName}
                              value={
                                archformSettingsData?.distance?.[
                                  `${field.fieldName}`
                                ]
                              }
                              buttonsRequired={false}
                              onChange={(e) =>
                                onArchformFieldChangeHandler(
                                  "distance",
                                  field.fieldName,
                                  e
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <b>Rotation</b>
                      <div style={{ display: `flex`, alignItems: `center` }}>
                        <p>Rotation Step Size (deg)</p>
                        {archformFields.rotation.map((field) => (
                          <div
                            style={{
                              display: `flex`,
                              flexDirection: `column`,
                              justifyContent: `flex-start`,
                            }}
                          >
                            <label htmlFor={field.fieldName}>
                              {field.fieldTitle}
                            </label>
                            <Input
                              name={field.fieldName}
                              value={
                                archformSettingsData?.rotation?.[
                                  `${field.fieldName}`
                                ]
                              }
                              buttonsRequired={false}
                              onChange={(e) =>
                                onArchformFieldChangeHandler(
                                  "rotation",
                                  field.fieldName,
                                  e
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      flexDirection: `row`,
                      gap: `1rem`,
                    }}
                  >
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="contained"
                      onClick={onArchformSettingsSaveHandler}
                    >
                      Save
                    </Button>
                    <Button
                      sx={{ width: `fit-content` }}
                      variant="outlined"
                      onClick={() => {
                        setIsArchformDialogOpen(false);
                      }}
                    >
                      Dismiss
                    </Button>
                  </div>
                </div>
              </Backdrop>
            </div>
            <div className={styles.inputBox}>
              <b>Treatment Notes:</b>

              <Input
                label="Treatment Notes"
                keyId="notes"
                onSubmit={changeField}
                onChange={(e) => setNotes(e)}
                value={notes}
                userId={patient?.user_id}
                multiline
                treatment
              />
            </div>
            <div className={styles.inputBox}>
              <b>Transformation URL:</b>

              <Input
                label="Transformation URL"
                keyId="transformation-url"
                onSubmit={changeField}
                onChange={(e) => setTransformationUrl(e)}
                value={transformationUrl}
                userId={patient?.user_id}
                treatment
              />
            </div>

            <div className={styles.inputBox}>
              <b>Doctor Feedback:</b>

              <Input
                userId={patient?.user_id}
                keyId="doctor-feedback"
                onSubmit={changeField}
                onChange={(e) => setFeedback(e)}
                value={feedback}
                treatment
                label="Doctor Feedback"
              />
            </div>
            {selectedPlan?.ipr_image?.url ? (
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <b>IPR Image:</b>
                <img
                  src={
                    selectedPlan?.ipr_image?.url + "?" + new Date().getTime()
                  }
                  ref={imgRef}
                  style={{ width: "100%", marginTop: "20px" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.fileUpload}>
            <div className={styles.files}>
              <b>Upper:</b>
              <FileUpload
                setSelectedPlan={setSelectedPlan}
                type="3d_files"
                userId={patient?.user_id}
                setNumUpper={setNumUpper}
                setNumLower={setNumLower}
                selectedPlan={selectedPlan}
                setPatient={setPatient}
                view="upper"
                for="Aligner Files"
              />

              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SingleSTLUploadField
                  description={"Upper Retainer File"}
                  name={"upper"}
                  id={"upper"}
                  fieldName="stl_retainer"
                  view={"upper"}
                  onChange={(e) => uploadRetainerFile(e, "upper")}
                  file={selectedPlan?.stl_retainer?.find(
                    (stl) => stl.view === "upper"
                  )}
                  treatment
                />
                {!isUpperUploaded ? <CircularProgress /> : ""}
                {selectedPlan?.stl_retainer?.find(
                  (stl) => stl.view === "upper"
                ) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteRetainer("upper")}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div style={{ marginTop: "50px" }}>
              <b>Lower:</b>
              <FileUpload
                setSelectedPlan={setSelectedPlan}
                type="3d_files"
                userId={patient?.user_id}
                setNumUpper={setNumUpper}
                setNumLower={setNumLower}
                setPatient={setPatient}
                view="lower"
                selectedPlan={selectedPlan}
                for="Aligner Files"
              />
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SingleSTLUploadField
                  description={"Lower Retainer File"}
                  name={"lower"}
                  id={"lower"}
                  fieldName="stl_retainer"
                  view={"lower"}
                  file={selectedPlan?.stl_retainer?.find(
                    (stl) => stl.view === "lower"
                  )}
                  onChange={(e) => uploadRetainerFile(e, "lower")}
                  treatment
                />
                {!isLowerUploaded ? <CircularProgress /> : ""}
                {selectedPlan?.stl_retainer?.find(
                  (stl) => stl.view === "lower"
                ) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => deleteRetainer("lower")}
                  >
                    Delete
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TreatmentSubForm;
