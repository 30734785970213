import { Clear } from "@mui/icons-material";
import { Box, Button, Input, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
export const ChangePasswordModal = ({ open, setShow, updateField }) => {
  const [password, setPassword] = useState();
  const [cnfPassword, setCnfPassword] = useState();

  const handleSubmit = (e) => {
    // API Req for change password
    e.preventDefault();
    if (password !== cnfPassword)
      alert("Password and Confirm Password should be same");
    else {
      updateField("password", password);
      setShow(false);
    }
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Clear
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={() => setShow(false)}
        />
        <h2>Change Password</h2>
        <form action="" onSubmit={(e) => handleSubmit(e)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px ",
            }}
          >
            <b>New Password:</b>
            <Input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "30px ",
            }}
          >
            <b>Confirm Password:</b>
            <Input
              name="confirm_password"
              type="password"
              value={cnfPassword}
              onChange={(e) => setCnfPassword(e.target.value)}
              required
            />
          </div>
          <Button
            style={{ marginTop: "20px" }}
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
