import { AccountCircle } from "@mui/icons-material";
import { Button, Grid, NativeSelect, TextField } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Register } from "../../Services/AuthService";
import styles from "./styles.module.css";

export const Signup = ({ isLoggedIn, setIsLoggedIn }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zohoStaffID, setZohoStaffID] = useState("");
  const [zohoUserID, setZohoUserID] = useState("");
  const [zohoServiceID, setZohoServiceID] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [degree, setDegree] = useState("");
  const [experience, setExperience] = useState("");
  const [userType, setUserType] = useState("admin");
  const [profileImg, setProfileImg] = useState(null);

  const history = useHistory();

  const handleSignup = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("staff_name", firstname);
    formData.append("email", email);
    formData.append("phone_number", phone);
    formData.append("staff_zoho_id", zohoStaffID);
    formData.append("service_zoho_id", zohoServiceID);
    formData.append("zoho_user_id", zohoUserID);
    formData.append("city", city);
    formData.append("degree", degree);
    formData.append("experience", experience);
    formData.append("password", password);
    formData.append("admin_user_type", userType);
    formData.append("profile_picture", profileImg);
    Register(formData).then((res) => {
      history.push("/");
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.formContainer}>
        <h1 className={styles.formTitle}>Create new user</h1>
        <form className={styles.form} onSubmit={(e) => handleSignup(e)}>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="staffname"
                label="Staffname"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstname}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="phone"
                label="Contact"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="zoho-staff-id"
                label="Zoho Staff ID"
                onChange={(e) => setZohoStaffID(e.target.value)}
                value={zohoStaffID}
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="zoho-service-id"
                label="Zoho Service ID"
                onChange={(e) => setZohoServiceID(e.target.value)}
                value={zohoServiceID}
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="zoho_user_id"
                label="Zoho User ID"
                onChange={(e) => setZohoUserID(e.target.value)}
                value={zohoUserID}
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="city"
                label="City"
                onChange={(e) => setCity(e.target.value)}
                value={city}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="degree"
                label="Degree"
                onChange={(e) => setDegree(e.target.value)}
                value={degree}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                //   className={styles.textField}
                id="experience"
                label="Experience"
                onChange={(e) => setExperience(e.target.value)}
                value={experience}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                type="password"
                //   className={styles.textField}
                id="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <b>User Type:</b> &nbsp;&nbsp;&nbsp;
              <NativeSelect
                style={{ width: "100px" }}
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
                inputProps={{
                  name: "change role",
                  id: "uncontrolled-native2",
                }}
              >
                <option value="admin">Admin </option>
                <option value="doctor">Doctor </option>
                <option value="sales">Sales</option>
                <option value="support">Support</option>
                <option value="planning_doctor">Planning Doctor </option>
                <option value="scanning_doctor">Scanning Doctor</option>
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item sx={{ display: `flex`, gap: `0.5rem`,alignItems:`center` }}>
              <b style={{width:`max-content`}}>User Image:</b>
              <input
                type="file"
                accept="image/*"
                className={styles.fileUpload}
                onChange={(e) => setProfileImg(e.target.files[0])}
              />
            </Grid>
          </Grid>

          <Grid
            className={styles.submit}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Register
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
