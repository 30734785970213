import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, NativeSelect } from "@mui/material";
import styles from "./Treatment.module.css";
import TreatmentSubForm from "../TreatmentSubForm/TreatmentSubForm";
import {
  ChangeCurrPlan,
  GetUserType,
  NewTreatmentPlan,
} from "../../Services/TreatmentService";
import { GetAllUsers, GetUser } from "../../Services/GetUsers";

function Treatment({
  patient,
  filteredData,
  setAllData,
  setPatient,
  setCurrentPlan,
}) {
  const [treatmentPlans, setTreatmentPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();
  const [currentPlanId, setCurrentPlanId] = useState();
  const [doctors, setDoctors] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [assignedDoctor, setAssignedDoctor] = useState("");
  const [assignedSalesPerson, setAssignedSalesPerson] = useState("");
  const addTreatmentPlan = () => {
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const formData = new FormData();
    formData.append("User_id", patient?.user_id);
    formData.append("Created_time", `${createdTime} ${time}`);
    NewTreatmentPlan(formData).then((res) => {
      console.log(res.data.tpid);
      GetUser({ user_id: patient?.user_id }).then((res) => {
        setPatient(res.data);
        setTreatmentPlans(res.data.treatment_plans);
        res.data.treatment_plans.forEach((plan) => {
          if (plan.plan_id === res.data.current_plan_selected) {
            setSelectedPlan(plan);
            setPatient(res?.data);
          }
        });
        const updatedUsers = filteredData.filter(
          (user) => user.user_id !== patient.user_id
        );
        setAllData([...updatedUsers, res.data]);
      });
    });
  };

  useEffect(() => {
    const currentPlan = patient?.treatment_plans?.filter(
      (plan) => plan?.plan_id === patient?.current_plan_selected
    )[0];
    console.log(currentPlan);
    GetUserType("doctor").then((res) => {
      setDoctors(res?.data?.admin_users);
      console.log(res?.data?.admin_users, patient?.assigned_doctor);
      const filterDoctor = res?.data?.admin_users?.find(
        (doctor) => doctor?.admin_user_id === patient?.assigned_doctor
      );

      patient?.assigned_doctor === "no-doctor" ||
      patient?.assigned_doctor === ""
        ? setAssignedDoctor("")
        : setAssignedDoctor(filterDoctor);
    });
    GetUserType("sales").then((res) => {
      setSalesPerson(res?.data?.admin_users);
      const filterSales = res?.data?.admin_users?.find(
        (sales) => sales?.admin_user_id === patient?.assigned_sales
      );
      console.log(filterSales);
      patient?.assigned_sales === "no-sales" || patient?.assigned_sales === ""
        ? setAssignedSalesPerson("")
        : setAssignedSalesPerson(filterSales);
    });
    setTreatmentPlans(patient?.treatment_plans ? patient?.treatment_plans : []);
    setSelectedPlan(currentPlan ? currentPlan : {});
    setCurrentPlanId(currentPlan?.current_plan_selected);
  }, []);
  useEffect(() => {
    const currentPlan = patient?.treatment_plans?.filter(
      (plan) => plan?.plan_id === patient?.current_plan_selected
    )[0];
    setSelectedPlan(currentPlan ? currentPlan : {});
  }, [patient]);
  const changePlan = (changedPlanId) => {
    const currentPlan = patient?.treatment_plans?.filter(
      (plan) => plan?.plan_id === changedPlanId
    )[0];
    const formData = new FormData();
    formData.append("User_id", patient?.user_id);
    formData.append("tp_id", changedPlanId);
    ChangeCurrPlan(formData).then((res) => {
      GetUser({ user_id: patient.user_id }).then((res) => {
        const plan = res?.data?.treatment_plans?.filter(
          (plan) => plan?.plan_id === changedPlanId
        )[0];
        setSelectedPlan(plan);
        setPatient(res.data);
      });
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.changePlan}>
        <Button onClick={addTreatmentPlan} variant="contained">
          New Treatment Plan
        </Button>
        <FormControl style={{ marginLeft: "30px" }}>
          <NativeSelect
            onChange={(e) => changePlan(e.target.value)}
            defaultValue={selectedPlan?.plan_id}
            value={selectedPlan?.plan_id}
            inputProps={{
              name: "change stage",
              id: "uncontrolled-native",
            }}
          >
            {treatmentPlans?.length === 0 ? (
              <option value="no-tp">No Treatment Plan Available</option>
            ) : (
              ""
            )}
            {treatmentPlans?.map((plan) => (
              <>
                {selectedPlan === {} ? (
                  <option value="no-plan">No Plan Selected</option>
                ) : (
                  ""
                )}
                <option value={plan.plan_id}>
                  {selectedPlan.plan_id === plan.plan_id
                    ? `Current Plan (${plan.plan_id})`
                    : `Treatment Plan ${plan.plan_id}`}
                </option>
              </>
            ))}
          </NativeSelect>
        </FormControl>
      </div>
      <div className={styles.treatmentContainer}>
        {treatmentPlans?.length !== 0 && selectedPlan ? (
          <TreatmentSubForm
            id={selectedPlan?.plan_id}
            doctors={doctors}
            salesPerson={salesPerson}
            selectedPlan={selectedPlan}
            setSelectedPlan={setSelectedPlan}
            assignedDoctor={assignedDoctor}
            assignedSalesPerson={assignedSalesPerson}
            setAssignedDoctor={setAssignedDoctor}
            setAssignedSalesPerson={setAssignedSalesPerson}
            patient={patient}
            setPatient={setPatient}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Treatment;
