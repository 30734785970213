import {
  Button,
  FormControl,
  FormControlLabel,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { ChangeFeedback } from "../../Services/FeedbackService";
import { GetUser } from "../../Services/GetUsers";
import Input from "../Input/Input";

import styles from "./AlignerFeedback.module.css";

const AlignerFeedback = ({ patient, onUserUpdateHandler }) => {
  const availableUpperSteps = patient?.progress?.progress_steps?.upper_aligners;
  const availableLowerSteps = patient?.progress?.progress_steps?.lower_aligners;
  const [availableSteps, setAvailableSteps] = useState(availableUpperSteps);
  const [alignerType, setAlignerType] = useState("upper");
  const [selectedStep, setSelectedStep] = useState(
    availableSteps?.[0].aligner_step
  );
  const [verdict, setVerdict] = useState(
    availableSteps?.[0].doctor_feedback.verdict
  );
  const [comment, setComment] = useState(
    availableSteps?.[0].doctor_feedback.comments
  );

  const onVerdictChange = (value) => {
    setVerdict(value);
  };
  const onCommentChange = (value) => {
    setComment(value);
  };

  const onFeedbackSubmit = () => {
    // console.log(verdict,comment);
    //send request
    ChangeFeedback(patient?.user_id, selectedStep, { verdict, comment }).then(
      (data) => {
        GetUser({ user_id: patient?.user_id }).then((res) =>
          onUserUpdateHandler(res.data)
        );
      }
    );
  };

  console.log(selectedStep);
  console.log(availableUpperSteps,availableLowerSteps)

  useEffect(() => {
    setVerdict(
      availableSteps?.find((step) => step.aligner_step === selectedStep)
        ?.doctor_feedback.verdict
    );
    setComment(
      availableSteps?.find((step) => step.aligner_step === selectedStep)
        ?.doctor_feedback.comments
    );
  }, [selectedStep, availableSteps]);
  const dates = availableSteps?.find(
    (step) => step.aligner_step === selectedStep
  )?.dates;

  return (
    <div>
      <div>
        <FormControl sx={{display:"flex",flexDirection:"row",gap:"1rem", alignItems:"center"}}>
          <label
            // className={classes.questionLabel}
            id="demo-radio-buttons-group-label"
          >
            Select Aligner Type:
          </label>
          <RadioGroup
          sx={{display:"flex",flexDirection:"row",gap:"1rem"}}
            // className={classes.questionOptions}
            aria-labelledby="demo-radio-buttons-group-label"
            // defaultValue="No"
            value={alignerType}
            onChange={(e) => {
              console.log(e.target.value);
              setAlignerType(e.target.value);
              setAvailableSteps(
                e.target.value === "upper" ? availableUpperSteps : availableLowerSteps
              );
            }}
            name="radio-buttons-group"
          >
            <FormControlLabel value="upper" control={<Radio />} label="Upper" />
            <FormControlLabel value="lower" control={<Radio />} label="Lower" />
          </RadioGroup>
        </FormControl>
      </div>
      {selectedStep ? (
        <div
          style={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <FormControl>
            <NativeSelect
              onChange={(e) => setSelectedStep(e.target.value)}
              defaultValue={availableSteps?.[0].aligner_step || "no-aligner"}
              value={selectedStep}
              inputProps={{
                name: "select step",
                id: "uncontrolled-native",
              }}
            >
              {availableSteps?.length === 0 && (
                <option value="no-aligner">No Aligners Yet</option>
              )}
              {availableSteps?.map((step) => (
                <option value={step.aligner_step}>
                  {`Aligner ${step.aligner_step}`}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
          {/* {selectedStep && (
            <h4>{`Date: ${
              availableSteps?.find((step) => step.aligner_step === selectedStep)
                ?.date_start
            }`}</h4>
          )} */}
        </div>
      ) : (
        <h2>No Aligners Yet</h2>
      )}
      {selectedStep && (
        <div style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}>
          <div
            style={{
              display: `flex`,
              flexDirection: `row`,
              justifyContent: `space-between`,
            }}
          >
            <h3>{`Date Start: ${
              availableSteps?.find((step) => step.aligner_step === selectedStep)
                ?.date_start
            }`}</h3>
            <h3>{`Date End: ${
              availableSteps?.find((step) => step.aligner_step === selectedStep)
                ?.date_end
            }`}</h3>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {Object.keys(dates || {}).map((columnTitle) => (
                      <TableCell align="center" sx={{ fontWeight: `bold` }}>
                        {columnTitle}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {Object.values(dates || {}).map((dateObj, i) => {
                      return (
                        <TableCell
                          align="center"
                          key={i}
                        >{`${dateObj.hours} hours : ${dateObj.minutes} minutes : ${dateObj.seconds} seconds`}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlignerFeedback;
