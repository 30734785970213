import axios from "axios";

const scanningPartnerNotification = (
  scanningPartnerNumber,
  formattedDateTime,
  user_id,
  name,
  address,
  phone,
  scanLink
) => {
  return axios.post(
    "https://api.interakt.ai/v1/public/message/",
    {
      countryCode: "+91",
      phoneNumber: scanningPartnerNumber,
      type: "Template",
      template: {
        name: "scan_partner_alert_v2",
        languageCode: "en",
        bodyValues: [formattedDateTime.toString(), name, address, phone, scanLink],
        buttonValues: {
          0: [user_id],
          1: [scanLink],
        },
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic aG9KZWdfR3JiNFZoUXpSTjVfZmZQTzFlMU1JTWJuUW12MEMzUnQ5SldiWTo=",
      },
    }
  );
};

const clientNotification = (
  clientNumber,
  formattedDateTime,
  scanningPartnerNumber
) => {
  return axios.post(
    "https://api.interakt.ai/v1/public/message/",
    {
      countryCode: "+91",
      phoneNumber: clientNumber,
      type: "Template",
      template: {
        name: "scan_client_alert_v3",
        languageCode: "en",
        bodyValues: [scanningPartnerNumber],
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic aG9KZWdfR3JiNFZoUXpSTjVfZmZQTzFlMU1JTWJuUW12MEMzUnQ5SldiWTo=",
      },
    }
  );
};

export { scanningPartnerNotification, clientNotification };
