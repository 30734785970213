import { AccountCircle } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AuthContext } from "../../context/AuthContext";
import { Login as AdminLogin } from "../../Services/AuthService";
import styles from "./styles.module.css";

export const Login = ({ isLoggedIn, setIsLoggedIn }) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const { isAuthenticated, setIsAuthenticated, setIsAuthDone } =
    useContext(AuthContext);

  const handleSignup = (e) => {
    e.preventDefault();
    const testObj = {
      password,
      email,
    };
    let formBody = [];

    for (let property in testObj) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(testObj[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    AdminLogin(formBody)
      .then((res) => {
        localStorage.setItem("isLoggedIn", JSON.stringify(true));
        setIsAuthDone(true);
      })
      .catch((err) => {
        toast.error("Incorrect Login Credentials", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  return (
    <div className={styles.container}>
      <ToastContainer />
      <div className={styles.formContainer}>
        <div>
          <img
            style={{ width: "150px" }}
            src="snazzyorange-logo.png"
            alt="logo"
          />
        </div>
        <h1 className={styles.formTitle}>Admin Login</h1>
        <form onSubmit={(e) => handleSignup(e)}>
          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                id="email"
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </Grid>
          </Grid>

          <Grid
            className={styles.inputField}
            container
            spacing={1}
            alignItems="flex-end"
            justify="center"
          >
            <Grid item>
              <TextField
                type="password"
                id="password"
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="flex-end" justify="center">
            <Grid item>
              <Button variant="contained" color="primary" type="submit">
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};
