import { faPencilAlt, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop } from "@mui/material";
import { display } from "@mui/system";
import React, { useEffect, useRef } from "react";
import { useState } from "react";

export const PhotoUploadField = (props) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const inputFile = useRef(null);

  const onChangeHandler = (e) => {
    setFile(e.target.files[0]);
    setFileUrl(URL.createObjectURL(e.target.files[0]));
    inputFile.current.disabled = true;
    props?.treatment
      ? props?.onChange(e)
      : props?.onChange &&
        props?.onChange(
          props?.fieldName,
          e.target.files[0],
          props?.fieldName,
          props?.view
        );
  };

  const onLabelClick = () => {
    if (inputFile.current.disabled === true) {
      //display image in overlay
      setOpen(true);
    }
  };

  const onEdit = () => {
    inputFile.current.disabled = false;
    inputFile.current.click();
  };

  useEffect(() => {
    if (props?.file !== undefined && props?.file !== null) {
      setFileUrl(props?.file.url);
      inputFile.current.disabled = true;
      // urlToObject(props?.file.url);
    }
    else{
      setFileUrl(null);
    }
  }, [props?.file]);

  return (
    <div
      style={{ width: `max-content` }}
      onMouseEnter={() => {
        if (fileUrl !== null && fileUrl !== undefined) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (fileUrl !== null && fileUrl !== undefined) {
          setShowEdit(false);
        }
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => setOpen(false)}
      >
        {fileUrl !== null && fileUrl !== undefined && (
          <img src={fileUrl} alt="uploaded file" style={{ width: `80%` }} />
        )}
      </Backdrop>
      <input
        accept="image/*"
        ref={inputFile}
        id={props?.id}
        type="file"
        name={props?.name}
        multiple={props?.multiple}
        onChange={onChangeHandler}
        hidden
      />
      <label htmlFor={props?.name} onClick={onLabelClick}>
        <div
          style={{
            borderRadius: `10px`,
            boxShadow: `0 2px 60px rgb(40 40 40 / 8%)`,
            width: `192px`,
            height: `180px`,
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            gap: `2rem`,
            padding: `16px`,
            background: `#fff`,
            cursor: `pointer`,
          }}
        >
          <div
            style={{
              width: `160px`,
              height: `128px`,
              border: `1px dashed rgb(204, 204, 204)`,
              boxSizing: `border-box`,
              borderRadius: `10px`,
              padding: `15px`,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: `center`,
            }}
          >
            {fileUrl !== null && fileUrl !== undefined ? (
              <img
                src={fileUrl}
                alt="uploaded file"
                style={{ width: `100%` }}
              />
            ) : (
              <div>
                <FontAwesomeIcon icon={faUpload} color="#BFBFBF" size="3x" />
              </div>
            )}
          </div>
          <div>
            <span>{props?.description}</span>
          </div>
        </div>
      </label>

      <div
        style={{
          position: `relative`,
          bottom: `82%`,
          left: `82%`,
          visibility: !showEdit && `hidden`,
          cursor: `pointer`,
        }}
        onClick={onEdit}
      >
        <div
          style={{
            backgroundColor: `#e3e1e1`,
            borderRadius: `10px`,
            padding: `10px`,
            width: `fit-content`,
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} color="#4A4A4A" size="1x" />
        </div>
      </div>
    </div>
  );
};
