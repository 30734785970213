import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import sidebarStyles from "./Sidebar.module.css";
import sidebarOptions from "./sidebarData.js";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { IsAuthenticated, Logout } from "../../Services/AuthService";

const drawerWidth = 240;

function Sidebar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user, setUser, setIsAuthenticated, setIsAuthDone } =
    useContext(AuthContext);
  const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const handleLogout = () => {
    Logout().then((res) => {
      localStorage.clear();
      setIsAuthenticated(false);
      setIsAuthDone(false);
      setUser(null);
    });
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <>
      <div>
        {/* <Toolbar /> */}
        {/* <h2 style={{ textAlign: "center" }}>
        Master <span style={{ color: "#f35a2e" }}> Snazzy</span>
      </h2> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            /* align-items: center; */
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "150px" }}
            src="snazzyorange-logo.png"
            alt="logo"
          />
        </div>
        <Divider />
        <List>
          <h3 className={sidebarStyles.heading}>
            <b>Patients</b>
          </h3>
          {sidebarOptions.map((option, index) => (
            <Link to={option.path} className={sidebarStyles.noLinkStyle}>
              <ListItem button key={index}>
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: option.color,
                    borderRadius: "50%",
                    marginRight: "5px",
                  }}
                ></div>
                <ListItemText primary={option.name} />
              </ListItem>
            </Link>
          ))}
        </List>
        <Divider />
      </div>
      {user?.admin_user_type === "admin" && (
        <>
          <Link to="/user-management" className={sidebarStyles.noLinkStyle}>
            <ListItem button>
              <ListItemText primary="User Management" />
            </ListItem>
          </Link>
          {/* <Link to="/admin" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Admins" />
              </ListItem>
            </Link>
            <Link to="/support" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Support" />
              </ListItem>
            </Link>{" "}
            <Link to="/sales" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Sales" />
              </ListItem>
            </Link>{" "}
            <Link to="/doctor" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Doctor" />
              </ListItem>
            </Link>{" "}
            <Link to="/scanning_doctor" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Scanning Doctor" />
              </ListItem>
            </Link>
            <Link to="/planning_doctor" className={sidebarStyles.noLinkStyle}>
              <ListItem button>
                <ListItemText primary="Planning Doctor" />
              </ListItem>
            </Link> */}
        </>
      )}

      <div style={{ textAlign: "center" }}>
        {user?.admin_user_type === "admin" && (
          <Button
            style={{ marginTop: "30px", width: "90%" }}
            variant="contained"
            onClick={() => history.push("/register")}
          >
            Create new user
          </Button>
        )}
        <Button
          style={{ marginTop: "30px", width: "90%" }}
          variant="contained"
          onClick={() => handleLogout()}
        >
          Logout
        </Button>
      </div>
    </>
  );

  return (
    <div className={sidebarStyles.sidebar}>
      <Box
        sx={{
          width: {
            xs: 10,
            sm: 240,
          },
          height: {
            sm: 0,
            xs:`fit-content`
          }
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: false,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}>
        </Drawer>
      </Box>
      {/* <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer> */}
      <Box
        // variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          backgroundColor: `white`,
          height: `100vh`,
          overflowY: `scroll`
        }}
        
      >
        {drawer}
      </Box>
    </div>
  );
}

export default Sidebar;
