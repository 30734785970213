import request from "./API";

export const GetBookings = (user_id, service_type) => {
  return request.get(`/admin-form/bookings/${user_id}/${service_type}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetScanDoctorAvailability = (date, doctor_id) => {
  return request.get(
    `/adminbooking/scandoctors/availability/${date}/${doctor_id}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const GetSalesPersonAvailability = (date, doctor_id) => {
  return request.get(`/adminbooking/sales/availability/${date}/${doctor_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const AddScanBooking = (data) => {
  return request.post(`/adminbooking/scandoctors/appointments`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const AddVideoBooking = (data) => {
  return request.post(`/adminbooking/sales/appointments`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const DeleteBooking = (id) => {
  return request.delete(`/adminbooking/scandoctors/appointments/${id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetBlockedSlots = (admin_id) => {
  return request.get(`/adminbooking/blockedslots?admin_id=${admin_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
