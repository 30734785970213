import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  Button,
  TextField,
  NativeSelect,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Paper,
  Link,
} from "@mui/material";
import { fontWeight } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  AddDiscountCode,
  AddPayment,
  GetPaymentDetails,
  RemoveDiscountCode,
  UpdateAlreadyCollectedAmount,
  UpdateCurrentTPBase,
} from "../../Services/TreatmentService";

import Input from "../Input/Input";
import PaymentDataTable from "../PaymentDataTable/PaymentDataTable";

import styles from "./PaymentData.module.css";

import { paymentStatus, subscriptionStatus } from "./statusVar";

function PaymentData({ patient }) {
  const [paymentData, setPaymentData] = useState({});
  const [currentPaymentType, setCurrentPaymentType] = useState("Scan Booking");

  const [currentTreatmentPlan, setCurrentTreatmentPlan] = useState(
    patient?.treatment_price?.filter(
      (tp) => tp.plan_id === patient?.current_plan_selected
    )?.[0]
  );

  const [manualPaymentModalOpen, setManualPaymentModalOpen] = useState(false);
  const [paymentFor, setPaymentFor] = useState("impression_kit");
  const [paymentMode, setPaymentMode] = useState("cash");
  const [referenceId, setReferenceId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(0);

  const onCurrentTPChangeHandler = (field) => (value) => {
    setCurrentTreatmentPlan((prev) => {
      const newPlan = { ...prev };
      newPlan[field] = value;
      return newPlan;
    });
  };

  const onCurrentTPFieldSubmitHandler = (field) => (e) => {
    if (field === "promo_code") {
      AddDiscountCode({
        user_id: patient?.user_id,
        promo_code: currentTreatmentPlan.promo_code,
      }).then((res) => {
        setCurrentTreatmentPlan((prev) => {
          console.log(res.data);
          const newPlan = { ...prev };
          newPlan.promo_code_discount = res.data.tp_price.promo_code_discount;
          newPlan.final_amount = res.data.tp_price.final_amount;
          return newPlan;
        });
      });
    } else if (field === "base_amount") {
      UpdateCurrentTPBase({
        user_id: patient?.user_id,
        change_type: `base_amount`,
        base_amount: Number(currentTreatmentPlan.base_amount),
      }).then((res) => {
        console.log(res.data);
        setCurrentTreatmentPlan((prev) => {
          const newPlan = { ...prev };
          newPlan.final_amount = res.data.tp_price.final_amount;
          return newPlan;
        });
      });
    } else if (field === "already_collected_amount") {
      UpdateAlreadyCollectedAmount({
        user_id: patient?.user_id,
        change_type: `already_collected`,
        already_collected: Number(
          currentTreatmentPlan.already_collected_amount
        ),
      }).then((res) => {
        console.log(res.data);
        setCurrentTreatmentPlan((prev) => {
          const newPlan = { ...prev };
          newPlan.final_amount = res.data.tp_price.final_amount;
          return newPlan;
        });
      });
    }
  };

  const onPromoCodeRemoveHandler = () => {
    RemoveDiscountCode({
      user_id: patient.user_id,
      promo_code: currentTreatmentPlan.promo_code,
    }).then((res) => {
      console.log(res.data);
      setCurrentTreatmentPlan((prev) => {
        const newPlan = { ...prev };
        newPlan.promo_code = "";
        newPlan.promo_code_discount = res.data.tp_price.promo_code_discount;
        newPlan.final_amount = res.data.tp_price.final_amount;
        return newPlan;
      });
    });
  };

  const addPayment = () => {
    const payload = {
      user_id: patient?.user_id,
      payment_type: paymentFor,
      mode: paymentMode,
      reference_id: referenceId,
      remarks: remarks,
      amount: Number(amount),
    };
    AddPayment(payload).then((res) => {
      setPaymentData((prev) => {
        const newData = JSON.parse(JSON.stringify(prev));
        console.log(newData);
        newData[res.data.manual_payment.payment_type].push(res.data.manual_payment);
        console.log(newData);
        return newData;
      });
    });
    //Update paymentData
    setManualPaymentModalOpen(false);
  };

  useEffect(() => {
    GetPaymentDetails(patient?.user_id).then((res) => {
      setPaymentData((prev) => {
        if (res?.data?.all_bookings) {
          const currentPaymentData = res?.data?.all_bookings;
          return currentPaymentData.reduce((acc, payment) => {
            if (!acc[payment.payment_type]) {
              acc[payment.payment_type] = [];
            }
            acc[payment.payment_type].push(payment);
            return acc;
          }, {});
        }
        return prev;
      });
    });
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setManualPaymentModalOpen(false);
        }}
        open={manualPaymentModalOpen}
      >
        <Box sx={{ padding: `1rem`, height: `max-content` }}>
          <DialogTitle>
            <div
              style={{
                display: `flex`,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `space-between`,
              }}
            >
              <Typography variant="h4">{`Add Payment`}</Typography>
              <div
                style={{
                  display: `flex`,
                  flexDirection: `row`,
                  gap: `1rem`,
                  alignItems: `center`,
                  fontSize: `1rem`,
                  fontWeight: 300,
                }}
              >
                <b>Reference Id</b>
                <TextField
                  type={"string"}
                  size="small"
                  onChange={(e) => setReferenceId(e.target.value)}
                  value={referenceId}
                />
              </div>
            </div>
          </DialogTitle>
          <Box
            sx={{
              padding: `1rem 1.5rem`,
              display: `flex`,
              flexDirection: `column`,
              gap: `1rem`,
              height: `max-content`,
            }}
          >
            <div style={{ display: `flex`, justifyContent: `space-between` }}>
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Type</b>
                <NativeSelect
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    setPaymentFor(e.target.value);
                  }}
                  inputProps={{
                    id: "uncontrolled-native2",
                  }}
                >
                  <option value="impression_kit">Impression Kit </option>
                  <option value="at_home">At home </option>
                  <option value="treatment">Treatment </option>
                </NativeSelect>
              </div>
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Mode</b>
                <NativeSelect
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    setPaymentMode(e.target.value);
                  }}
                  inputProps={{
                    id: "uncontrolled-native2",
                  }}
                >
                  <option value="cash">Cash </option>
                  <option value="online">Online </option>
                </NativeSelect>
              </div>
            </div>
            <div
              style={{
                display: `flex`,
                flexDirection: `row`,
                alignItems: `center`,
                justifyContent: `space-between`,
              }}
            >
              <div
                style={{
                  display: `flex`,
                  gap: `1rem`,
                  alignItems: `center`,
                }}
              >
                <b>Amount</b>
                <TextField
                  type={"number"}
                  size="small"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Remark</b>
                <TextField
                  size="small"
                  multiline
                  minRows={3}
                  fullWidth
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>
            </div>

            <Button
              variant="contained"
              sx={{ width: `max-content` }}
              onClick={addPayment}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
      <div style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}>
        <div
          className={styles.formControl}
        >
          <FormControl>
            <InputLabel id="current-payment-type-label">
              Payment Categories
            </InputLabel>
            <Select
              labelId="current-payment-type-label"
              id="current-payment-type"
              value={currentPaymentType}
              label="Payment Categories"
              onChange={(e) => {
                setCurrentPaymentType(e.target.value);
              }}
            >
              <MenuItem value={`Scan Booking`}>Scan Booking</MenuItem>
              <MenuItem value={`Product Booking`}>Product Booking</MenuItem>
              <MenuItem value={`Treatment Order`}>Treatment Order</MenuItem>
            </Select>
          </FormControl>
          <Box style={{ margin: `1rem 0` }}>
            <Button
              variant="contained"
              onClick={setManualPaymentModalOpen.bind(null, true)}
            >
              <span>Add Payment</span>
            </Button>
          </Box>
        </div>
        {currentPaymentType.toLowerCase().includes(`treatment`) &&
          currentTreatmentPlan && (
            <Box>
              <Paper
                elevation={3}
                className={styles.paymentDataPaper}
              >
                <div className={styles.tp_elements}>
                  <b> Treatment Plan: </b>
                  <span>{currentTreatmentPlan.plan_id}</span>
                </div>
                <div className={styles.tp_elements}>
                  <b> Total Amount: </b>
                  <span>{currentTreatmentPlan.final_amount_reference}</span>
                </div>
                <div className={styles.tp_elements}>
                  <b> Already Collected: </b>
                  <Input
                    name="already_collected_amount"
                    type="number"
                    onChange={()=>{}}
                    value={patient?.at_scan_collected_amount}
                    style={{ width: `100px` }}
                    onSubmit={()=>{}}
                    disabled
                  />
                </div>
                <div className={styles.tp_elements}>
                  <b> Base Amount: </b>
                  <Input
                    name="base_amount"
                    type="number"
                    onChange={onCurrentTPChangeHandler("base_amount")}
                    value={currentTreatmentPlan.base_amount}
                    style={{ width: `110px` }}
                    onSubmit={onCurrentTPFieldSubmitHandler("base_amount")}
                    disabled
                  />
                </div>
                <div className={styles.tp_elements}>
                  <b> Remaining Amount: </b>
                  <Input
                    name="remaining_amount"
                    type="number"
                    onChange={onCurrentTPChangeHandler("remaining_amount")}
                    value={currentTreatmentPlan.final_amount_reference-patient?.at_scan_collected_amount-currentTreatmentPlan.promo_code_discount || null}
                    style={{ width: `110px` }}
                    onSubmit={onCurrentTPFieldSubmitHandler("remaining_amount")}
                    disabled
                  />
                </div>
                <div className={styles.tp_elements}>
                  <b>EMI Cycle Amount: </b>
                  <span>
                    {Math.round(currentTreatmentPlan.emi_cycle_amount)}
                  </span>
                </div>
                <div className={styles.tp_elements}>
                  <b> EMI Cycles: </b>
                  <span>{currentTreatmentPlan.emi_cycles}</span>
                </div>
                <div style={{ width: `90%` }} className={styles.tp_elements}>
                  <b> Promo code: </b>{" "}
                  <div style={{ flex: 1 }}>
                    <Input
                      name="promo_code"
                      // autoFocus={true}
                      onChange={onCurrentTPChangeHandler("promo_code")}
                      value={currentTreatmentPlan.promo_code}
                      style={{ width: `100px` }}
                      onSubmit={onCurrentTPFieldSubmitHandler("promo_code")}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: `white`,
                      padding: "11px 12px",
                      borderRadius: `100%`,
                      display: "flex",
                      alignItems: "center",
                      height: "fit-content",
                      border: "2px solid #174EA6",
                      cursor: `pointer`,
                    }}
                    onClick={onPromoCodeRemoveHandler}
                  >
                    <FontAwesomeIcon icon={faTrash} color="#174EA6" size="xs" />
                  </div>
                </div>
                <div className={styles.tp_elements}>
                  <b>Promo Code Discount:</b>
                  <span>
                    {Math.round(currentTreatmentPlan.promo_code_discount)}
                  </span>
                </div>
              </Paper>
            </Box>
          )}
        {(() => {
          switch (currentPaymentType) {
            case `Scan Booking`:
              return (
                <PaymentDataTable
                  attributes={[`order_id`, `creation_time`, `status`, `mode`,`remarks`,`amount`]}
                  tableData={paymentData[currentPaymentType] || []}
                  primaryKey="order_id"
                />
              );
            case `Product Booking`:
              return (
                <PaymentDataTable
                  attributes={[`order_id`, `creation_time`, `status`, `mode`,`remarks`,`amount`]}
                  tableData={paymentData[currentPaymentType] || []}
                  primaryKey="order_id"
                />
              );
            case `Treatment Order`:
              return (
                <div>
                  <div>
                    <h4>Subscription(Treatment Order): General</h4>
                    <PaymentDataTable
                      attributes={[
                        `order_id`,
                        `creation_time`,
                        `status`,
                        `amount`,
                      ]}
                      tableData={paymentData[`Subscription(Treatment Order): General`] || []}
                      primaryKey="order_id"
                      columnHeads={[
                        `order_id`,
                        `creation_time`,
                        `status`,
                        `emi_(p/m)`,
                      ]}
                    />
                  </div>
                  <div>
                    <h4>Subscription(Treatment Order): Payment</h4>
                    <PaymentDataTable
                      attributes={[
                        `order_id`,
                        `creation_time`,
                        `status`,
                        `amount`,
                      ]}
                      tableData={paymentData[`Subscription(Treatment Order): Payment`] || []}
                      primaryKey="order_id"
                    />
                  </div>
                  <div>
                    <h4>Downpayment(Treatment Order)</h4>
                    <PaymentDataTable
                      attributes={[
                        `order_id`,
                        `creation_time`,
                        `status`,
                        `mode`,
                        `remarks`,
                        `amount`,
                      ]}
                      tableData={paymentData[`Downpayment(Treatment Order)`] || []}
                      primaryKey="order_id"
                    />
                  </div>
                  <div>
                    <h4>Pre-Book(Treatment Order)</h4>
                    <PaymentDataTable
                      attributes={[
                        `order_id`,
                        `creation_time`,
                        `status`,
                        `mode`,
                        // `remarks`,
                        `amount`,
                      ]}
                      tableData={paymentData[`Treatment Prebook`] || []}
                      primaryKey="order_id"
                    />
                  </div>
                </div>
              );
            default:
              return null;
          }
        })()}
        <div           style={{
            display: `flex`,
            flexDirection: `row`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}>
        <Link variant="h6" href={patient?.priority_scan_link_49} underline="none">
            Pre Pay Link - Simple Cases{" "}
          </Link>
          <Link variant="h6" href={patient?.priority_scan_link_69} underline="none">
            Pre Pay Link - Complex Cases{" "}
          </Link>
        </div>
      </div>
    </>
  );
}

export default PaymentData;
