import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  Typography,
  Button,
  TextField,
  NativeSelect,
} from "@mui/material";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import Input from "../Input/Input.jsx";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  AddBooking,
  AddScanBooking,
  AddVideoBooking,
  DeleteBooking,
  GetBookings,
  GetSalesPersonAvailability,
  GetScanDoctorAvailability,
} from "../../Services/BookingServices";
import { GetUserType } from "../../Services/TreatmentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateProfile } from "../../Services/ProfileService.js";
import { GetAllUsers } from "../../Services/GetUsers.js";
import axios from "axios";
import { clientNotification, scanningPartnerNotification } from "../../Services/SendWhatsappNotification.js";

const PatientBookings = ({ patient, setAllData, setPatient }) => {
  const [threeDScanBookings, setThreeDScanBookings] = useState([]);
  const [doctorConsultationBookings, setDoctorConsultationBookings] = useState(
    []
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);

  //Add Booking
  const [createBookingModalOpen, setCreateBookingModalOpen] = useState(false);
  const [bookingType, setBookingType] = useState("scan");
  const [assignablePersons, setAssignablePersons] = useState([]);
  const [assignedDoctor, setAssignedDoctor] = useState(null);
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [bookingDate, setBookingDate] = useState(new Date());
  const [selectBookingTime, setSelectBookingTime] = useState("");
  const [doctorSelect, setDoctorSelect] = useState(false);
  const [address, setAddress] = useState({
    house_no: patient?.address?.house_no,
    address_line_1: patient?.address?.address_line_1,
    city: patient?.address?.city,
    pincode: patient?.address?.pincode,
  });
  console.log(address);
  const [timeArray, setTimeArray] = useState([]);
  const [bookingAmount, setBookingAmount] = useState(null);
  const [bookingPaymentStatus, setBookingPaymentStatus] = useState(null);
  const [bookingDuration, setBookingDuration] = useState(90);
  const [bookingIssue, setBookingIssue] = useState(null);

  const addressfieldTitleMap = {
    house_no: "House no.",
    address_line_1: "Address",
    city: "City",
    pincode: "Pincode",
  };

  const AreObjFieldsFilled = (obj, map = null) => {
    for (const [key, value] of Object.entries(obj)) {
      // console.log(`${key}: ${value}`);
      if (value === "" || value === null) {
        toast.error(`Please fill ${map !== null ? map[key] : key} field`, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
    }
    return true;
  };

  const fetchBookings = useCallback(
    async (service_type) => {
      try {
        const response = await GetBookings(patient.user_id, service_type);
        console.log(response.data, service_type);
        switch (service_type) {
          case "at_home":
            setThreeDScanBookings(response.data);
            break;
          case "video_consultation":
            setDoctorConsultationBookings(response.data);
            break;
          default:
            console.log("Wrong input for fetchBookings");
        }
      } catch (err) {
        switch (service_type) {
          case "at_home":
            setThreeDScanBookings([]);
            break;
          case "video_consultation":
            setDoctorConsultationBookings([]);
            break;
          default:
            console.log("Something went wrong");
        }
      }
    },
    [patient?.user_id]
  );

  const onBookingAddressChange = (field, fieldVal) => {
    setAddress((prev) => {
      const newAddress = { ...prev, [field]: fieldVal };
      return newAddress;
    });
  };

  const createBooking = () => {
    const date = bookingDate
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .join("-");

    const date2 = bookingDate.toISOString().slice(0, 10);
    const time = `${selectBookingTime}:00`;
    const datetime = `${date2} ${time}`;

    if (selectBookingTime === "") {
      toast.error("Please select a time slot", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (bookingType === "scan") {
      if (!AreObjFieldsFilled(address, addressfieldTitleMap)) {
        return;
      }
      const payload = {
        appointment_type: "at_home",
        timings: { start: datetime },
        patient_id: patient.user_id,
        issue: bookingIssue,
        // duration: bookingDuration,
        assigned_user: selectedPersonnel,
        amount: bookingAmount,
        status: bookingPaymentStatus,
        address: address,
      };
      const { issue, ...checkablePayload } = payload;
      if (!AreObjFieldsFilled(checkablePayload)) {
        return;
      }
      AddScanBooking(payload)
        .then((res) => {
          console.log(res);
          fetchBookings("at_home");
          const scanningPartner = assignablePersons.find(
            (person) => person.admin_user_id === selectedPersonnel
          );
          const scanningPartnerNumber =
            scanningPartner.staff_contact.substr(-10);
          const clientNumber = !patient.is_b2b?patient.contact.substr(-10):assignedDoctor.staff_contact.substr(-10);;
          const formattedDateTime = new Date(datetime).toLocaleString(
            undefined,
            {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            }
          );
          const scanLink = patient.is_b2b?`https://zfrmz.in/hpcrkRLRSBx0Hywi5PjJ?ref_id=${patient.zoho_ref_id}&doc_name=${encodeURIComponent(assignedDoctor.firstname?assignedDoctor.firstname:""+assignedDoctor.lastname?" "+assignedDoctor.lastname:"")}&doc_phone=${assignedDoctor.staff_contact.replace(/^\+91/, '')}&doc_mail=${encodeURIComponent(assignedDoctor.staff_email)}&pt_name=${encodeURIComponent(patient.firstname)}%20${encodeURIComponent(patient.lastname)}`: !!patient.workdrive_url?patient.workdrive_url:patient.direct_link;
          scanningPartnerNotification(
            scanningPartnerNumber,
            formattedDateTime,
            patient.user_id,
            patient.firstname + " " + patient.lastname,
            `${address?.house_no}, ${address?.address_line_1}, ${address?.city}, Pincode: ${address?.pincode}`,
            clientNumber,
            scanLink,
          );
          clientNotification(
            clientNumber,
            formattedDateTime,
            scanningPartnerNumber
          );
        })
        .catch((err) => {
          err.response.data &&
            err.response.data.error_message ===
              "Invalid type of the parameters in request body" &&
            toast.error("Please recheck fields", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (bookingType === "video") {
      const payload = {
        appointment_type: "video_consultation",
        timings: { start: datetime },
        patient_id: patient.user_id,
        issue: bookingIssue,
        // duration: bookingDuration,
        assigned_user: selectedPersonnel,
        status: bookingPaymentStatus || "free",
        amount: bookingAmount || "0",
      };
      const { issue, ...checkablePayload } = payload;
      if (!AreObjFieldsFilled(checkablePayload)) {
        return;
      }
      AddVideoBooking(payload)
        .then((res) => {
          console.log(res);
          fetchBookings("video_consultation");
        })
        .catch((err) => {
          err.response.data &&
            err.response.data.error_message ===
              "Invalid type of the parameters in request body" &&
            toast.error("Please recheck fields", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
    // console.log("hey");
    setCreateBookingModalOpen(false);
  };

  useEffect(() => {
    setAssignablePersons([]);
    if (bookingType === "video") {
      GetUserType("sales").then((res) => {
        setAssignablePersons(res?.data?.admin_users);
      });
    } else if (bookingType === "scan") {
      GetUserType("scanning_doctor").then((res) => {
        setAssignablePersons(res?.data?.admin_users);
      });
    }
  }, [bookingType]);

  useEffect(() => {
    if (selectedPersonnel !== null && selectedPersonnel !== "no-sales") {
      setDoctorSelect(true);
      if (bookingType === "scan") {
        GetScanDoctorAvailability(
          bookingDate
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .join("-"),
          selectedPersonnel
        ).then((data) => {
          setTimeArray((prev) => {
            const newTimeArray = Object.keys(data.data).filter(
              (time, index) => Object.values(data.data)[index] === true
            );
            return newTimeArray;
          });
        });
      } else if (bookingType === "video") {
        GetSalesPersonAvailability(
          bookingDate
            .toLocaleDateString("en-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .join("-"),
          selectedPersonnel
        ).then((data) => {
          setTimeArray((prev) => {
            const newTimeArray = Object.keys(data.data).filter(
              (time, index) => Object.values(data.data)[index] === true
            );
            return newTimeArray;
          });
        });
      }
    }
  }, [selectedPersonnel, bookingDate]);

  useEffect(()=>{
    if(patient.assigned_doctor){
      GetUserType("doctor").then((res) => {
        const docList = res?.data?.admin_users;
        if(docList.length!==0){
          setAssignedDoctor(docList.filter(doc=>doc.admin_user_id === patient.assigned_doctor)[0]);
        }
      });
    }
  },[])

  useEffect(() => {
    fetchBookings("at_home");
    fetchBookings("video_consultation");
  }, [patient, fetchBookings]);

  console.log(patient);

  const onCardClick = (booking) => {
    setSelectedBooking(booking);
    setModalOpen(true);
  };

  const onBookingDeletionHandler = (id) => {
    DeleteBooking(id)
      .then((res) => {
        console.log(res);
        setModalOpen(false);
        fetchBookings("at_home");
        fetchBookings("video_consultation");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const bookingCards = (booking) => {
    return (
      <Card
        sx={{ maxWidth: 345 }}
        onClick={() => {
          onCardClick(booking);
        }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {`Booking ID: ${booking?.booking_id}`}
            </Typography>
            <Typography gutterBottom variant="caption" color="text.secondary">
              {`Created at: ${booking?.creation_time.split(" ")[0]}`}
            </Typography>
            {booking?.service === "at_home" && (
              <>
                <div
                  style={{
                    display: `flex`,
                    alignItems: `center`,
                    gap: `0.5rem`,
                  }}
                >
                  <Typography variant="subtitle1">Payment Status:</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${booking?.status}`}
                  </Typography>
                </div>
                <div
                  style={{
                    display: `flex`,
                    alignItems: `center`,
                    gap: `0.5rem`,
                  }}
                >
                  <Typography variant="subtitle1">Amount:</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {`${
                      booking?.amount !== undefined ? booking?.amount : "N/A"
                    }`}
                  </Typography>
                </div>
              </>
            )}
            <div
              style={{ display: `flex`, alignItems: `center`, gap: `0.5rem` }}
            >
              <Typography variant="subtitle1">Timings:</Typography>
              <Typography variant="body2" color="text.secondary">
                {`${booking?.timings.start}`}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const bookingDialog = (booking) => {
    return (
      <>
        <DialogTitle sx={{ display: `flex`, gap: `2rem` }}>
          <Typography variant="h4">{`Booking ID: ${booking?.booking_id}`}</Typography>
          <button
            style={{
              backgroundColor: `white`,
              padding: "11px 12px",
              borderRadius: `100%`,
              display: "flex",
              alignItems: "center",
              height: "fit-content",
              border: "2px solid #174EA6",
              cursor: `pointer`,
            }}
            onClick={() => {
              onBookingDeletionHandler(booking?.booking_id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} color="#174EA6" size="md" />
          </button>
        </DialogTitle>
        <Box
          sx={{
            padding: `8px 24px`,
            display: `flex`,
            flexDirection: `column`,
            gap: `8px`,
          }}
        >
          {booking?.service === "at_home" && (
            <>
              <div
                style={{ display: `flex`, alignItems: `center`, gap: `0.5rem` }}
              >
                <Typography variant="h6">Payment Status:</Typography>
                <Typography variant="p" color="text.secondary">
                  {`${booking?.status}`}
                </Typography>
              </div>
              <div
                style={{ display: `flex`, alignItems: `center`, gap: `0.5rem` }}
              >
                <Typography variant="h6">Amount:</Typography>
                <Typography variant="p" color="text.secondary">
                  {`${booking?.amount !== undefined ? booking?.amount : "N/A"}`}
                </Typography>
              </div>
            </>
          )}
          <div style={{ display: `flex`, alignItems: `center`, gap: `0.5rem` }}>
            <Typography variant="h6">Timings:</Typography>
            <Typography variant="p" color="text.secondary">
              {`${booking?.timings.start}`}
            </Typography>
          </div>
          <div
            style={{ display: `flex`, alignItems: `baseline`, gap: `0.5rem` }}
          >
            <Typography variant="h6">Issue:</Typography>
            <Typography variant="p" color="text.secondary">
              {booking?.issue}
            </Typography>
          </div>
          {booking?.service === "at_home" && (
            <div
              style={{ display: `flex`, alignItems: `baseline`, gap: `0.5rem` }}
            >
              <Typography variant="h6">Scan Address:</Typography>
              <Typography variant="p" color="text.secondary">
                {`${booking?.scan_address?.house_no}, ${booking?.scan_address?.address_line_1}, ${booking?.scan_address?.city}, Pincode: ${booking?.scan_address?.pincode}`}
              </Typography>
            </div>
          )}
          <div
            style={{ display: `flex`, alignItems: `baseline`, gap: `0.5rem` }}
          >
            <Typography variant="h6">Zoho ID:</Typography>
            <Typography variant="p" color="text.secondary">
              {booking?.zoho_id}
            </Typography>
          </div>
          <div
            style={{ display: `flex`, alignItems: `baseline`, gap: `0.5rem` }}
          >
            <Typography variant="h6">Assigned Personnel:</Typography>
            <Typography variant="p" color="text.secondary">
              {booking?.assign_name}
            </Typography>
          </div>
        </Box>
      </>
    );
  };

  const ZohoWorkdriveURLHandler = (field_name, value) => {
    UpdateProfile({
      user_id: patient?.user_id,
      type: field_name,
      [field_name]: value,
    }).then((res) => {
      console.log(res);
    });
  };

  return (
    <>
      <ToastContainer />

      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setModalOpen(false);
        }}
        open={modalOpen}
      >
        {bookingDialog(selectedBooking)}
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setCreateBookingModalOpen(false);
          setSelectedPersonnel(null);
          setTimeArray([]);
        }}
        open={createBookingModalOpen}
      >
        <Box sx={{ padding: `1rem`, height: `max-content` }}>
          <DialogTitle>
            <Typography variant="h4">{`Add Booking`}</Typography>
          </DialogTitle>
          <Box
            sx={{
              padding: `8px 24px`,
              display: `flex`,
              flexDirection: `column`,
              gap: `8px`,
              height: `max-content`,
            }}
          >
            <div style={{ display: `flex`, justifyContent: `space-between` }}>
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Type</b>
                <NativeSelect
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    setBookingType(e.target.value);
                    setSelectedPersonnel(null);
                    setTimeArray([]);
                  }}
                  inputProps={{
                    id: "uncontrolled-native2",
                  }}
                >
                  <option value="scan">Scan consultation </option>
                  <option value="video">Video consultation </option>
                </NativeSelect>
              </div>
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Date</b>
                <DatePicker
                  selected={bookingDate}
                  onChange={(date) => setBookingDate(date)}
                />
              </div>
            </div>
            <div
              style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}
            >
              <div
                style={{
                  display: `flex`,
                  gap: `1rem`,
                  alignItems: `center`,
                  flex: 1,
                }}
              >
                <b>Assigned Individual</b>
                <NativeSelect
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    setSelectedPersonnel(e.target.value);
                  }}
                  defaultValue="no-sales"
                  inputProps={{
                    name: "change sales person",
                    id: "uncontrolled-native1",
                  }}
                >
                  <option value="no-sales">
                    {bookingType === "scan"
                      ? `Select Scanning Doctor`
                      : `Select Sales Person`}
                  </option>

                  {assignablePersons?.map((user) => (
                    <option value={user?.admin_user_id}>
                      {user?.staff_name}
                    </option>
                  ))}
                </NativeSelect>
              </div>
              <div
                style={{ display: `flex`, flexDirection: `column`, flex: 1 }}
              >
                <b>Time Slot</b>
                <div style={{ display: `flex`, flexWrap: `wrap`, gap: `1rem` }}>
                  {doctorSelect ? (
                    timeArray.length !== 0 ? (
                      timeArray.map((item, index) => (
                        <Button
                          variant="outlined"
                          key={index}
                          size="small"
                          // className={classes.timeData}
                          style={
                            selectBookingTime === item
                              ? { background: "#2f5be9", color: "white" }
                              : null
                          }
                          onClick={() => setSelectBookingTime(item)}
                        >
                          <div>
                            <span>{item}</span>
                          </div>
                        </Button>
                      ))
                    ) : (
                      <Button
                        variant="outlined"
                        key="no-slots"
                        // className={classes.timeData}
                        disabled
                      >
                        <div>
                          <h3>No Slots Available</h3>
                        </div>
                      </Button>
                    )
                  ) : (
                    <div style={{ width: "100%" }}>
                      <p style={{ textAlign: "center", margin: "1.5rem 0" }}>
                        Please assign individual
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {bookingType === "scan" && (
              <>
                <div
                  style={{
                    display: `flex`,
                    gap: `1rem`,
                    alignItems: `center`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                  }}
                >
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>House no.</b>
                    <TextField
                      type={"string"}
                      size="small"
                      onChange={(e) =>
                        onBookingAddressChange("house_no", e.target.value)
                      }
                      value={address.house_no}
                    />
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>Address</b>
                    <TextField
                      type={"string"}
                      size="small"
                      onChange={(e) =>
                        onBookingAddressChange("address_line_1", e.target.value)
                      }
                      value={address.address_line_1}
                    />
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>City</b>
                    <TextField
                      type={"string"}
                      size="small"
                      onChange={(e) =>
                        onBookingAddressChange("city", e.target.value)
                      }
                      value={address.city}
                    />
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>Pincode</b>
                    <TextField
                      type={"number"}
                      size="small"
                      onChange={(e) =>
                        onBookingAddressChange(
                          "pincode",
                          e.target.value.toString()
                        )
                      }
                      value={address.pincode}
                    />
                  </div>
                </div>
                <div
                  style={{ display: `flex`, justifyContent: `space-between` }}
                >
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>Amount</b>
                    <TextField
                      type={"number"}
                      size="small"
                      onChange={(e) => setBookingAmount(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: `flex`,
                      gap: `1rem`,
                      alignItems: `center`,
                    }}
                  >
                    <b>Payment Status</b>
                    <NativeSelect
                      style={{ width: "200px" }}
                      onChange={(e) => {
                        setBookingPaymentStatus(e.target.value);
                      }}
                      defaultValue="select-status"
                      inputProps={{
                        name: "set payment status",
                        id: "uncontrolled-native1",
                      }}
                    >
                      <option value="select-status">Select status</option>
                      <option value="paid">Paid</option>
                      {/* <option value="unpaid">Unpaid</option> */}
                      <option value="collect_cash">Collect Cash</option>
                    </NativeSelect>
                  </div>
                </div>
              </>
            )}
            {/* {bookingType === "video" && (
              <div
                style={{ display: `flex`, gap: `1rem`, alignItems: `center` }}
              >
                <b>Duration</b>
                <TextField
                  type={"number"}
                  size="small"
                  onChange={(e) => setBookingDuration(e.target.value)}
                />
              </div>
            )} */}
            <div
              style={{ display: `flex`, gap: `2.3rem`, alignItems: `center` }}
            >
              <b>Issue</b>
              <TextField
                size="small"
                multiline
                minRows={3}
                onChange={(e) => setBookingIssue(e.target.value)}
              />
            </div>
            <Button
              variant="contained"
              sx={{ width: `max-content` }}
              onClick={createBooking}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
      <div style={{ margin: `1rem 0` }}>
        <Button
          variant="outlined"
          onClick={setCreateBookingModalOpen.bind(null, true)}
        >
          Add Booking
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <b>Zoho Workdrive URL: </b>
        <Input
          value={patient?.workdrive_url}
          name="workdrive_url"
          onSubmit={ZohoWorkdriveURLHandler}
          multiline={true}
        />
      </div>
      <div style={{ display: `flex`, flexDirection: `column`, gap: `2rem` }}>
        <div>
          <Typography gutterBottom variant="h4" component="div">
            3D Scan Bookings:
          </Typography>
          <Box sx={{ display: `flex`, flexDirection: `column`, gap: `2rem` }}>
            <Box>
              <div style={{ display: `flex`, flexWrap: `wrap`, gap: `1rem` }}>
                {threeDScanBookings.map((booking) => {
                  return bookingCards(booking);
                })}
              </div>
            </Box>
            {/* <Box>
              <Typography gutterBottom variant="h5" component="div">
                Unpaid:
              </Typography>
              <div style={{ display: `flex`, flexWrap: `wrap`, gap: `1rem` }}>
                {threeDScanBookings
                  .filter((booking) => booking?.status === `unpaid`)
                  .map((booking) => {
                    return bookingCards(booking);
                  })}
              </div>
            </Box> */}
            {/* <Box>
              <Typography gutterBottom variant="h5" component="div">
                Collect Cash:
              </Typography>
              <div style={{ display: `flex`, flexWrap: `wrap`, gap: `1rem` }}>
                {threeDScanBookings
                  .filter((booking) => booking?.status === `collect_cash`)
                  .map((booking) => {
                    return bookingCards(booking);
                  })}
              </div>
            </Box> */}
          </Box>
        </div>
        <div>
          <Typography gutterBottom variant="h4" component="div">
            Video Consultation Bookings:
          </Typography>
          <div style={{ display: `flex`, flexWrap: `wrap`, gap: `1rem` }}>
            {doctorConsultationBookings.map((booking) => {
              return bookingCards(booking);
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientBookings;
