import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  NativeSelect,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import Input from "../Input/Input.jsx";
import styles from "./AdminProfile.module.css";
import { UpdateProfile } from "../../Services/ProfileService";
import {
  GetAdminUsers,
  GetAllUsers,
  GetUsersByRole,
  UpdateAdmin,
} from "../../Services/GetUsers.js";
import { GetUserType } from "../../Services/TreatmentService.js";
import { useParams } from "react-router-dom";
import { RotateLeft } from "@mui/icons-material";
import { ChangePasswordModal } from "./ChangePasswordModal.jsx";
import { AuthContext } from "../../context/AuthContext.jsx";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { faTrash } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

import {
  AddScanBooking,
  GetBlockedSlots,
  DeleteBooking,
} from "../../Services/BookingServices.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let hourSlots = [];

for (let index = 0; index < 24; index++) {
  hourSlots.push(index < 10 ? "0" + index : `${index}`);
}

const minSlots = ["00", "15", "30", "45"];

const StyledTableRow = styled(TableRow)(({ theme }) => ({ //Beware: This function has been implemented differently somewhere else
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "default",
}));

const StyledTableCell = styled(TableCell)( //Beware: This function has been implemented differently somewhere else
  ({ theme, cursorType = "default" }) => ({
    cursor: cursorType,
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  })
);

function AdminProfile({ patient, setAllData, setPatient }) {
  const [curr, setCurr] = useState({});
  const { id, role } = useParams();
  const [profileImg, setProfileImg] = useState();
  const [show, setShow] = useState(false);
  const [isBlockSlotModalOpen, setIsBlockSlotModalOpen] = useState(false);
  const [blockDateTimeSlot, setBlockDateTimeSlot] = useState({
    startDate: new Date(),
    startHour: "00",
    startMin: "00",
    endDate: new Date(),
    endHour: "00",
    endMin: "00",
  });
  const [blockedSlots, setBlockedSlots] = useState(null);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const blockSlot = () => {
    const startDate = blockDateTimeSlot.startDate
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .join("-");
    const startTime = `${blockDateTimeSlot.startHour}:${blockDateTimeSlot.startMin}`;
    const startDateTime = `${startDate} ${startTime}`;

    const endDate = blockDateTimeSlot.endDate
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .join("-");
    const endTime = `${blockDateTimeSlot.endHour}:${blockDateTimeSlot.endMin}`;
    const endDateTime = `${endDate} ${endTime}`;

    const payload = {
      appointment_type: "block_slot",
      timings: { start: startDateTime, end: endDateTime },
      assigned_user: curr.admin_user_id,
    };
    AddScanBooking(payload)
      .then((res) => {
        console.log(res);
        GetBlockedSlots(curr.admin_user_id).then((res) =>
          setBlockedSlots(res.data.appointments)
        );
      })
      .catch((err) => {
        err.response?.data &&
          err.response?.data.error_message ===
            "Invalid type of the parameters in request body" &&
          toast.error("Please recheck fields", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onBlockSlotDelete = (id) => {
    DeleteBooking(id)
      .then((res) => {
        console.log(res);
        GetBlockedSlots(curr.admin_user_id).then((res) =>
          setBlockedSlots(res.data.appointments)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log(role, id);
    if (user?.admin_user_type === "admin") {
      console.log("Admin User");
      GetAdminUsers().then((res) => {
        console.log(res.data);
        const currUser = res.data.find((admin) => admin.admin_user_id === id);
        console.log(currUser);
        setCurr(currUser);
        GetBlockedSlots(currUser.admin_user_id).then((res) =>
          setBlockedSlots(res.data.appointments)
        );
      });
    } else if (id === user?.admin_user_id) {
      console.log("Present User");
      setCurr(user);
    } else {
      setCurr(null);
    }
  }, [role]);
  console.log(curr);
  const onBlockDateTimeSlot = (field, value) => {
    setBlockDateTimeSlot((prev) => {
      const newSlots = { ...prev };
      newSlots[field] = value;
      return newSlots;
    });
  };
  const updateField = (name, value) => {
    let formData = new FormData();
    formData.append("admin_user_id", id);
    formData.append("type", name);
    formData.append(name, value);
    UpdateAdmin(formData).then((res) => {
      GetAdminUsers().then((data) => {
        const allUsers = data.data;
        const updatedUser = allUsers.find(
          (admin) => admin.admin_user_id === id
        );
        setCurr(updatedUser);
        if (name === "admin_user_type") {
          history.push("/user-management");
        }
      });
    });
  };

  const updateProfileImage = (e) => {
    let formData = new FormData();
    formData.append("admin_user_id", id);
    formData.append("type", "profile_picture");
    formData.append("profile_picture", profileImg);
    UpdateAdmin(formData).then((res) => {
      GetAdminUsers().then((data) => {
        const allUsers = data.data;
        const updatedUser = allUsers.find(
          (admin) => admin.admin_user_id === id
        );
        setCurr(updatedUser);
        setProfileImg(null);
      });
    });
  };
  //   const modifyAssigned = (e, name) => {
  //     UpdateAdmin({
  //       user_id: patient?.user_id,
  //       type: name,
  //       [name]: e.target.value,
  //     }).then((res) => {
  //       GetAllUsers().then((data) => {
  //         const allUsers = data.data;
  //         const updatedUser = allUsers.find(
  //           (user) => user.user_id === patient.user_id
  //         );
  //         setPatient(updatedUser);
  //         setAllData(allUsers);
  //       });
  //     });
  //   };
  if (!curr)
    return (
      <h1 style={{ textAlign: "center", marginTop: "30px" }}>
        You are not authorised to access this profile
      </h1>
    );

  return (
    <>
      <ToastContainer />

      <ChangePasswordModal
        open={show}
        setShow={setShow}
        updateField={updateField}
      />
      <Dialog
        maxWidth="md"
        fullWidth={true}
        onClose={() => {
          setIsBlockSlotModalOpen(false);
        }}
        open={isBlockSlotModalOpen}
      >
        <DialogTitle>Block Slots</DialogTitle>
        <DialogContent sx={{ height: "90vh" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Typography variant="h6">Block a slot:</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ width: "150px" }}>Starting Date:</Typography>
                <DatePicker
                  selected={blockDateTimeSlot.startDate}
                  onChange={(date) => {
                    onBlockDateTimeSlot("startDate", date);
                  }}
                  style={{ maxWidth: "200px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Typography>Starting Time:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Hours:</Typography>
                    <NativeSelect
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        onBlockDateTimeSlot("startHour", e.target.value);
                      }}
                      value={blockDateTimeSlot.startHour}
                      inputProps={{
                        name: "change role",
                        id: "uncontrolled-native2",
                      }}
                    >
                      {hourSlots.map((val) => (
                        <option value={val}>{val} </option>
                      ))}
                    </NativeSelect>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Minutes:</Typography>
                    <NativeSelect
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        onBlockDateTimeSlot("startMin", e.target.value);
                      }}
                      value={blockDateTimeSlot.startMin}
                      inputProps={{
                        name: "change role",
                        id: "uncontrolled-native2",
                      }}
                    >
                      {minSlots.map((val) => (
                        <option value={val}>{val} </option>
                      ))}
                    </NativeSelect>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ width: "150px" }}>Ending Date:</Typography>
                <DatePicker
                  selected={blockDateTimeSlot.endDate}
                  onChange={(date) => {
                    onBlockDateTimeSlot("endDate", date);
                  }}
                  style={{ maxWidth: "200px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <Typography>Ending Time:</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Hours:</Typography>
                    <NativeSelect
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        onBlockDateTimeSlot("endHour", e.target.value);
                      }}
                      value={blockDateTimeSlot.endHour}
                      inputProps={{
                        name: "change role",
                        id: "uncontrolled-native2",
                      }}
                    >
                      {hourSlots.map((val) => (
                        <option value={val}>{val} </option>
                      ))}
                    </NativeSelect>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "200px",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Minutes:</Typography>
                    <NativeSelect
                      style={{ width: "100px" }}
                      onChange={(e) => {
                        onBlockDateTimeSlot("endMin", e.target.value);
                      }}
                      value={blockDateTimeSlot.endMin}
                      inputProps={{
                        name: "change role",
                        id: "uncontrolled-native2",
                      }}
                    >
                      {minSlots.map((val) => (
                        <option value={val}>{val} </option>
                      ))}
                    </NativeSelect>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ width: "200px" }}
              onClick={blockSlot}
            >
              Block
            </Button>
          </Box>
          <Box>
            <Typography variant="h6">Blocked Slots:</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Block Table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Start Date
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      Start Time
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      End Date
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      End Time
                    </TableCell>
                    <TableCell
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                        // cursor: "pointer",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                {blockedSlots !== null && (
                  <TableBody>
                    {blockedSlots.map((row, index) => (
                      <StyledTableRow
                        key={row.booking_id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <StyledTableCell>
                          {row?.timings.start?.slice(0, 10)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.timings.start?.slice(11)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.timings.end?.slice(0, 10)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.timings.end?.slice(11)}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() => onBlockSlotDelete(row.booking_id)}
                          cursorType="pointer"
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            color="#174EA6"
                            size="md"
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
      </Dialog>

      <div className={styles.formContainer}>
        <div className={styles.formBody}>
          <div className={styles.formInpFields}>
            <div className={styles.inputBox} style={{ marginBottom: "40px" }}>
              {curr?.profile_image !== null ? (
                <img
                  src={curr?.profile_image?.url}
                  style={{
                    width: "250px",
                    height: "250px",
                    borderRadius: "50%",
                    marginTop: "30px",
                  }}
                  alt=""
                />
              ) : (
                ""
              )}
              <div>
                <h2 style={{ textAlign: `center` }}>{curr?.staff_name}</h2>
                {user?.admin_user_type === "admin" ? (
                  <Button variant="contained" onClick={(e) => setShow(true)}>
                    Change Password
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className={styles.inputBox}>
              <b>First Name</b>
              <Input
                value={curr?.staff_name}
                name="first_name"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Email</b>
              <Input
                value={curr?.staff_email}
                name="email"
                onSubmit={updateField}
              />
            </div>
            <div className={styles.inputBox}>
              <b>Contact</b>
              <Input
                value={curr?.staff_contact}
                name="phone_number"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Zoho Staff ID</b>
              <Input
                value={curr?.staff_zoho_id}
                name="staff_zoho_id"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Zoho Service ID</b>
              <Input
                value={curr?.service_zoho_id}
                name="service_zoho_id"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Zoho User ID</b>
              <Input
                value={curr?.zoho_user_id}
                name="zoho_user_id"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Start Working Hour</b>
              <Input
                value={curr?.start_working_hour}
                name="start_working_hour"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>End Working Hour</b>
              <Input
                value={curr?.end_working_hour}
                name="end_working_hour"
                onSubmit={updateField}
              />
            </div>

            <div className={styles.inputBox}>
              <b>Block Slots</b>
              <Button
                variant="contained"
                onClick={(e) => setIsBlockSlotModalOpen(true)}
              >
                Select and view Slots here
              </Button>
            </div>

            <div className={styles.inputBox}>
              <b>City</b>
              <Input
                value={curr?.staff_city}
                name="city"
                onSubmit={updateField}
              />
            </div>
            <div className={styles.inputBox}>
              <b>Degree</b>
              <Input
                value={curr?.degree}
                name="degree"
                onSubmit={updateField}
              />
            </div>
            <div className={styles.inputBox}>
              <b>Experience</b>
              <Input
                value={curr?.experience}
                name="experience"
                onSubmit={updateField}
              />
            </div>
            <div className={styles.inputBox}>
              <b>Admin User Type</b>
              {user?.admin_user_type === "admin" ? (
                <NativeSelect
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    updateField("admin_user_type", e.target.value);
                  }}
                  value={curr?.admin_user_type}
                  defaultValue={curr?.admin_user_type}
                  inputProps={{
                    name: "change role",
                    id: "uncontrolled-native2",
                  }}
                >
                  <option value="admin">Admin </option>
                  <option value="doctor">Doctor </option>
                  <option value="sales">Sales</option>
                  <option value="planning_doctor">Planning Doctor </option>
                  <option value="scanning_doctor">Scanning Doctor</option>
                </NativeSelect>
              ) : (
                <b>{curr?.admin_user_type}</b>
              )}
            </div>
            <div className={styles.inputBox} style={{ marginTop: "20px" }}>
              <b>Profile Image</b>
              <input
                type="file"
                accpet="image/*"
                style={{ width: "234px" }}
                onChange={(e) => setProfileImg(e.target.files[0])}
              />
            </div>
            <div
              className={styles.inputBox}
              style={{ display: "flex", alignItems: "center" }}
            >
              {profileImg ? (
                <img
                  src={URL.createObjectURL(profileImg)}
                  style={{ width: "400px", marginTop: "30px" }}
                  alt=""
                />
              ) : (
                ""
              )}

              {profileImg ? (
                <Button
                  variant="contained"
                  onClick={(e) => updateProfileImage(e)}
                >
                  Upload
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminProfile;
