import { TextField } from "@mui/material";
import * as React from "react";

import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Input = (props) => {
  const initialVal = props?.value;
  const [value, setValue] = React.useState("");
  const [showButtons, setShowButtons] = React.useState(false);
  const [buttonsRequired, setButtonsRequired] = React.useState(true);
  // const [initialVal, setInitialVal] = React.useState("");

  const onChange = (e) => {
    setValue(e.target.value);
    setShowButtons(true);
    console.log(value);
    console.log(props?.value)
    props.onChange && props.onChange(e.target.value);
  };

  const onSubmitHandler = () => {
    props?.treatment
      ? props.onSubmit(props.userId, props.keyId, value, props?.multiline)
      : props?.onSubmit && props.onSubmit(props?.name, value);
    setShowButtons(false);
  };

  React.useEffect(() => {
    if (props?.value !== undefined && props?.value !== null) {
      // setInitialVal(props?.value);
      setValue(props?.value);
    }
  }, [props?.value]);

  React.useEffect(()=>{
    if (props?.buttonsRequired!==undefined && props?.buttonsRequired===false){
      setButtonsRequired(false);
    }
  },[props?.buttonsRequired])

  //For displaying the conctrols based on prev values
  // React.useEffect(() => {
  //   console.log(value)
  //   if (
  //     initialVal !== null &&
  //     initialVal !== undefined &&
  //     initialVal === value
  //   ) {
  //     setShowButtons(false);
  //   }
  // }, [value]);

  return (
    <div style={{ display: `flex`, alignItems: `center`, gap: "1rem" }}>
      <TextField
        fullWidth={props?.fullWidth}
        id={props?.id}
        name={props?.name}
        type={props?.type}
        autoFocus={props?.autoFocus}
        multiline={props?.multiline}
        minRows={props?.multiline && 3}
        onChange={onChange}
        onBlur={props?.onBlur}
        error={props?.error}
        helperText={props?.helperText}
        defaultValue={value}
        value={value}
        size={props?.size || "small"}
        style={{ ...props?.style, margin: "10px" }}
        {...(props?.disabled ? { disabled: "true" } : {})}
      />
      {showButtons && buttonsRequired && (
        <div style={{ display: `flex`, gap: `0.5rem`, alignItems: `center` }}>
          <div
            style={{
              backgroundColor: `#8BC34A`,
              padding: "10px",
              borderRadius: `100%`,
              display: "flex",
              alignItems: "center",
              height: "fit-content",
              cursor: `pointer`,
            }}
            onClick={onSubmitHandler}
          >
            <FontAwesomeIcon icon={faCheck} color="white" size="xs" />
          </div>
          <div
            style={{
              backgroundColor: `white`,
              padding: "8px 10px",
              borderRadius: `100%`,
              display: "flex",
              alignItems: "center",
              height: "fit-content",
              border: "2px solid #174EA6",
              cursor: `pointer`,
            }}
            onClick={() => {
              setValue("");
              props?.onChange && props.onChange("");
              setShowButtons(false);
            }}
          >
            <FontAwesomeIcon icon={faTimes} color="#174EA6" size="xs" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;
