import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PatientInfo from "../../Components/PatientInfo/PatientInfo";
import PatientViewHeader from "../../Components/PatientViewHeader/PatientViewHeader";
import { GetUser } from "../../Services/GetUsers";
import styles from "./PatientView.module.css";
function PatientView({ filteredData, setAllData,onUserUpdateHandler }) {
  const { id } = useParams();
  const [patient, setPatient] = useState(null);
  console.log(filteredData, setAllData);
  useEffect(() => {
    console.log("heyy");
    if (filteredData)
      setPatient(filteredData.find((patient) => patient?.user_id === id));
    if (!filteredData.find((patient) => patient?.user_id === id)) {
      console.log(id);
      if (id) {
        GetUser({ user_id: id }).then((res) => {
          setPatient(res.data); 
        });
      }
    }
  }, [filteredData, id]);
  // console.log(patient);
  return patient === null ? (
    <div></div>
  ) : (
    <div className={styles.container}>
      <PatientViewHeader
        patient={patient}
        filteredData={filteredData}
        setAllData={setAllData}
        onUserUpdateHandler={onUserUpdateHandler}
      />
      <PatientInfo
        patient={patient}
        filteredData={filteredData}
        setAllData={setAllData}
        setPatient={setPatient}
        onUserUpdateHandler={onUserUpdateHandler}
      />
    </div>
  );
}

export default PatientView;
