import request from "./API";

export const Register = (data) => {
  return request.post("/admin-form/register", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const Login = (data) => {
  return request.post("/admin-form/login", data, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  });
};

export const Logout = () => {
  return request.post("/admin-form/logout", {
    headers: { "Content-Type": "application/json" },
  });
};

export const IsAuthenticated = () => {
  return request
    .get("/admin-form/auth-check", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((data) => data.data)
    .catch((err) => err.response);
};
