import {
  Button,
  FormControl,
  NativeSelect,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from "react-toastify";

import { GetAllUsers } from "../../Services/GetUsers";
import { CreatePatient } from "../../Services/TreatmentService";

import styles from "./NewPatient.module.css";

function NewPatient({ filteredData, setAllData }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [clientType, setClientType] = useState("");

  const history = useHistory();
  const addNewPatient = (e) => {
    e.preventDefault();

    //API call for new patient
    CreatePatient({
      email,
      contact: phone,
      first_name: firstName,
      last_name: lastName,
      pincode: address,
      clientType: clientType,
    })
      .then((res) => {
        // console.log(res);
        if (res.data.error_message) {
          console.log(res);
          toast.error(res.data.error_message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.success("Successfully created new user", {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            history.push(`/patient/${res.data.user_id}`);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(err.response.data.error_message, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div className={styles.rootContainer}>
        <div className={styles.container}>
          <h1>New Patient</h1>
          <form
            className={styles.formContainer}
            onSubmit={(e) => addNewPatient(e)}
          >
            <TextField
              className={styles.formInput}
              id="filled-fname-input"
              label="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              variant="standard"
            />
            <TextField
              className={styles.formInput}
              id="filled-lname-input"
              label="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              variant="standard"
            />
            <TextField
              className={styles.formInput}
              id="filled-email-input"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              variant="standard"
            />
            <TextField
              className={styles.formInput}
              id="filled-phone-input"
              label="Contact"
              inputProps={{ maxLength: 10 }}
              onChange={(e) => setPhone(e.target.value)}
              type="number"
              variant="standard"
            />
            <TextField
              className={styles.formInput}
              id="filled-address-input"
              label="Address (Pincode)"
              onChange={(e) => setAddress(e.target.value)}
              type="number"
              variant="standard"
            />
            <FormControl sx={{ width: `70%`, marginTop: "1rem" }}>
              <NativeSelect
                onChange={(e) => {
                  setClientType(e.target.value);
                }}
                inputProps={{
                  name: "change-treatment-type",
                  id: "uncontrolled-native",
                }}
                sx={{ width: `100%` }}
              >
                <option value={""}>Is B2B?</option>
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </NativeSelect>
            </FormControl>
            <Button
              type="submit"
              style={{ marginTop: "40px" }}
              variant="contained"
            >
              Add Patient
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}

export default NewPatient;
