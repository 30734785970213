import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetAllUsers, GetUser } from "../../Services/GetUsers";
import { ChangeStage } from "../../Services/TreatmentService";
import sidebarOptions from "../Sidebar/sidebarData";

const subStagesScanBooked = [
  "Scan Booked",
  "Scan booked but not done",
  "Not interested",
  "Scan Done",
  "Scan Done at 49k",
  "Scan Done at 69k",
];

const subStagesPrepareTP = [
  "Prepare TP",
  "Prepare TP-Priority",
  "Need more info",
  "On Hold",
  "TP Shared",
  "Rejected",
];

const subStagesTPReview = [
  "Pending",
  "Accepted",
  "On Hold",
  "Revision Plan",
  "Rejected",
];

const subStagesTPOut = [
  "TP Consultation",
  "Revise Plan",
  "Not Interested",
  "Interested Later",
  "Negotiation",
  "Price Too High",
  "Lost to competition",
  "Plan not good",
];

const subStagesInTreatment = [
  "Ongoing",
  "Review Progress",
  "Remove Attachment",
];

const subStagesResolutionRequired = [
  "Case Review",
  "Pending Scan",
  "Treatment Planning",
  "Review TP",
  "TP Consultation",
  "Manufacturing",
  "Delivery",
  "Resolved",
  "Rejected",
];

const subStagesLost = ["Lost", "Competition", "Pricing", "Treatment Plan"];

const stageSubStageMapping = {
  "Scan Booked": subStagesScanBooked,
  "TP in Progress": subStagesPrepareTP,
  "Review TP": subStagesTPReview,
  "TP Approved": subStagesTPOut,
  "In Treatment": subStagesInTreatment,
  "Resolution Required": subStagesResolutionRequired,
  Lost: subStagesLost,
};

function PatientViewHeader({
  patient,
  filteredData,
  setAllData,
  onUserUpdateHandler,
}) {
  const { firstname, lastname, email, stage_new, user_id } =
    patient !== undefined
      ? patient
      : { firstname: "", lastname: "", email: "", stage: "", user_id: "" };
  const stage = stage_new?.stage;
  const [currentStage, setCurrentStage] = useState(stage);
  const [currentSubStage, setCurrentSubStage] = useState(stage_new?.substage);
  const [isStageOrSubStageChanging, setIsStageOrSubStageChanging] =
    useState(false);
  const statusMap = {
    "New Lead": 10,
    "Doctor Consultation": 20,
    "Scan Booked": 30,
    "Impression Booked": 40,
    "TP in Progress": 50,
    "Review TP": 60,
    "TP Approved": 70,
    Manufacturing: 80,
    Delivery: 90,
    "Pending IPR": 100,
    "In Treatment": 110,
    "Resolution Required": 120,
    "Treatment Done": 130,
    Rejected: 140,
    Lost: 150,
  };

  useEffect(() => {
    setCurrentStage(stage);
    console.log(stage);
  }, [stage]);

  useEffect(() => {
    setCurrentSubStage(patient?.stage_new?.substage);
  }, [patient]);

  const changeStage = (newStage) => {
    let currentStage;
    for (const property in statusMap) {
      if (statusMap[property] === parseInt(newStage)) {
        currentStage = property;
      }
    }
    const newPatient = { ...patient, stage: currentStage };
    const newFilteredData = filteredData.map((patient) =>
      patient.user_id === user_id ? newPatient : patient
    );
    setCurrentStage(currentStage);
    setIsStageOrSubStageChanging(true);
    ChangeStage({ user_id: user_id, stage: currentStage, change_type: "stage" })
      .then((res) => {
        toast.success("Stage changed successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsStageOrSubStageChanging(false);
        GetAllUsers().then((data) => {
          setAllData(data.data);
        });
      })
      .catch((err) => console.error(err));
  };

  const onSubStageChange = (newSubStage) => {
    setCurrentSubStage(newSubStage);
    setIsStageOrSubStageChanging(true);
    ChangeStage({
      user_id: user_id,
      sub_stage: newSubStage,
      change_type: "sub_stage",
    })
      .then((res) => {
        if (res.status === 200)
          GetUser({ user_id }).then((res) => onUserUpdateHandler(res.data));
        toast.success("Sub Stage changed successfully", {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIsStageOrSubStageChanging(false);
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <ToastContainer />
      <Paper
        elevation={0}
        style={{
          backgroundColor: "#ffffff",
          boxShadow: " 1px 2px 30px rgb(0 0 0 / 8%)",
          marginBottom: "40px",
          borderRadius: "0px",
          padding: "10px",
          paddingTop: `20px`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexWrap: `wrap`,
          }}
        >
          <Box
            sx={{
              // display: "flex",
              // justifyContent: "space-evenly",
              // alignItems: "center",
              // flexWrap: `wrap`,
              textAlign:`center`
            }}
          >
            <h2>
              {(firstname ? firstname : "") + " " + (lastname ? lastname : "")}
            </h2>
            <h3>{email}</h3>
          </Box>
          <h2
            style={{
              color: sidebarOptions.find(
                (stageObject) => stageObject.name === currentStage
              )?.color,
            }}
          >
            {
              sidebarOptions.find(
                (stageObject) => stageObject.name === currentStage
              )?.name
            }
          </h2>
          <Box sx={{ display: `flex`, gap: `2rem` }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Change Stage
              </InputLabel>
              <NativeSelect
                onChange={(e) => changeStage(e.target.value)}
                inputProps={{
                  name: "change-stage",
                  id: "uncontrolled-native",
                }}
                defaultValue={statusMap[currentStage]}
                value={statusMap[currentStage]}
                disabled={isStageOrSubStageChanging}
              >
                <option value={10}>New Lead</option>
                <option value={20}>Doctor Consultation</option>
                <option value={30}>Scan Booked</option>
                <option value={40}>Impression Booked</option>
                <option value={50}>TP in Progress</option>
                <option value={60}>Review TP</option>
                <option value={70}>TP Approved</option>
                <option value={80}>Manufacturing</option>
                <option value={90}>Delivery</option>
                <option value={100}>Pending IPR</option>
                <option value={110}>In Treatment</option>
                <option value={120}>Resolution Required</option>
                <option value={130}>Treatment Done</option>
                <option value={140}>Rejected</option>
                <option value={150}>Lost</option>
              </NativeSelect>
            </FormControl>
            {Object.keys(stageSubStageMapping).find(
              (stage) => currentStage === stage
            ) && (
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Change Sub Stage
                </InputLabel>
                <NativeSelect
                  onChange={(e) => onSubStageChange(e.target.value)}
                  inputProps={{
                    name: "change-sub-stage",
                    id: "uncontrolled-native",
                  }}
                  defaultValue={currentSubStage}
                  value={currentSubStage}
                  disabled={isStageOrSubStageChanging}
                >
                  {/* {currentStage === "Scan Booked" ? (
                    <>
                      {subStagesScanBooked.map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                    </>
                  ) : (
                    <>
                      {subStagesPrepareTP.map((val) => (
                        <option value={val}>{val}</option>
                      ))}
                    </>
                  )} */}
                  {stageSubStageMapping[currentStage].map((val) => (
                    <option value={val}>{val}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default PatientViewHeader;
