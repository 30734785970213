const sidebarOptions = [
  {
    name: "All Patients",
    path: "/",
    color: "#91969e",
  },
  {
    name: "New Lead",
    color: "#63cbfa",

    path: "/default",
  },
  {
    name: "Doctor Consultation",
    color: "#1E4273",

    path: "/doctor-consultation",
  },
  {
    name: "Scan Booked",
    path: "/scan-booked",
    color: "#fbae3d",
  },
  {
    name: "Impression Booked",
    path: "/impression-booked",
    color: "#0a74fa",
  },
  {
    name: "TP in Progress",
    path: "/prepare-tp",
    color: "#f15073",
  },
  {
    name: "Review TP",
    path: "/tp-review",
    color: "#E5E515",
  },
  {
    name: "TP Approved",
    path: "/tp-out",
    color: "#66d481",
  },
  {
    name: "Manufacturing",
    path: "/manufacturing",
    color: "#595ad6",
  },
  {
    name: "Delivery",
    path: "/delivery",
    color: "#f2452f",
  },
  {
    name: "Pending IPR",
    path: "/pending-ipr",
    color: "#C1E0E0",
  },
  {
    name: "In Treatment",
    path: "/in-treatment",
    color: "#90959e",
  },
  {
    name: "Resolution Required",
    path: "/resolution-required",
    color: "#FFCA28",
  },
  {
    name: "Treatment Done",
    path: "/treatment-done",
    color: "#0BC142",
  },
  { name: "Rejected", path: "/rejected", color: "red" },
  {
    name: "Lost",
    path: "/lost",
    color: "#BE2F2C",
  },
];

export default sidebarOptions;
