import {
    Button,
    FormControl,
    NativeSelect,
    Select,
    TextField,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useHistory } from "react-router-dom";
  import { v4 as uuid } from "uuid";
  import { ToastContainer, toast } from "react-toastify";
  
  import { GetAllUsers } from "../../Services/GetUsers";
  import { CreateB2BPatient, CreatePatient } from "../../Services/TreatmentService";
  
  import styles from "./NewB2BPatient.module.css";
  
  function NewB2BPatient({ filteredData, setAllData }) {
    // const [firstName, setFirstName] = useState("");
    // const [lastName, setLastName] = useState("");
    // const [email, setEmail] = useState("");
    // const [phone, setPhone] = useState("");
    // const [address, setAddress] = useState("");
    // const [clientType, setClientType] = useState("");

    const [docName, setDocName] = useState("");
    const [docEmail, setDocEmail] = useState("");
    const [docPhone, setDocPhone] = useState("");
    const [patientFirstName, setPatientFirstName] = useState("");
    const [patientLastName, setPatientLastName] = useState("");
  
    const history = useHistory();
    const addNewPatient = (e) => {
      e.preventDefault();
  
      //API call for new patient
      CreateB2BPatient({
        doc_email:docEmail,
        doc_contact: docPhone,
        doc_name: docName,
        first_name: patientFirstName,
        last_name: patientLastName,
      })
        .then((res) => {
          // console.log(res);
          if (res.data.error_message) {
            console.log(res);
            toast.error(res.data.error_message, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success("Successfully created new user", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(() => {
              history.push(`/patient/${res.data.user_id}`);
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err.response);
          toast.error(err.response.data.error_message, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    };
  
    return (
      <>
        <ToastContainer />
        <div className={styles.rootContainer}>
          <div className={styles.container}>
            <h1>New B2B Patient</h1>
            <form
              className={styles.formContainer}
              onSubmit={(e) => addNewPatient(e)}
            >
                <TextField
                className={styles.formInput}
                id="filled-dname-input"
                label="Doctor Name"
                onChange={(e) => setDocName(e.target.value)}
                type="text"
                variant="standard"
              />
              <TextField
                className={styles.formInput}
                id="filled-email-input"
                label="Doctor Email"
                onChange={(e) => setDocEmail(e.target.value)}
                type="email"
                variant="standard"
              />
              <TextField
                className={styles.formInput}
                id="filled-phone-input"
                label="Doctor Phone"
                inputProps={{ maxLength: 10 }}
                onChange={(e) => setDocPhone(e.target.value)}
                type="number"
                variant="standard"
              />
              <TextField
                className={styles.formInput}
                id="filled-fname-input"
                label="Patient First Name"
                onChange={(e) => setPatientFirstName(e.target.value)}
                type="text"
                variant="standard"
              />
              <TextField
                className={styles.formInput}
                id="filled-lname-input"
                label="Patient Last Name"
                onChange={(e) => setPatientLastName(e.target.value)}
                type="text"
                variant="standard"
              />
              {/* <TextField
                className={styles.formInput}
                id="filled-address-input"
                label="Address (Pincode)"
                onChange={(e) => setAddress(e.target.value)}
                type="number"
                variant="standard"
              />
              <FormControl sx={{ width: `70%`, marginTop: "1rem" }}>
                <NativeSelect
                  onChange={(e) => {
                    setClientType(e.target.value);
                  }}
                  inputProps={{
                    name: "change-treatment-type",
                    id: "uncontrolled-native",
                  }}
                  sx={{ width: `100%` }}
                >
                  <option value={""}>Is B2B?</option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </NativeSelect>
              </FormControl> */}
              <Button
                type="submit"
                style={{ marginTop: "40px" }}
                variant="contained"
              >
                Add Patient
              </Button>
            </form>
          </div>
        </div>
      </>
    );
  }
  
  export default NewB2BPatient;
  