import {
  faPencilAlt,
  faTimes,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop } from "@mui/material";
import { display } from "@mui/system";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import React, { Suspense, useEffect, useRef } from "react";
import { useState } from "react";
import { StlViewer } from "../StlViewer/StlViewer";

// extend({ OrbitControls });

export const SingleSTLUploadField = (props) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  // const [open, setOpen] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const inputFile = useRef(null);
  const downloadAnchor = useRef(null);

  const onChangeHandler = (e) => {
    setFile(e.target.files[0]);
    setFileUrl(URL.createObjectURL(e.target.files[0]));
    // console.log("change")
    inputFile.current.disabled = true;
    // props?.onChange &&
    //   props?.onChange(
    //     props?.fieldName,
    //     e.target.files[0],
    //     props?.fieldName,
    //     props?.view
    //   );
    props?.treatment
      ? props?.onChange(e)
      : props?.onChange &&
        props?.onChange(
          props?.fieldName,
          e.target.files[0],
          props?.fieldName,
          props?.view
        );
  };

  useEffect(() => {
    console.log("file", props?.file);
  }, []);
  const onLabelClick = () => {
    if (inputFile.current.disabled === true) {
      // setOpen(true);
    }
    // console.log(`Hellooo`)
  };

  const onEdit = () => {
    inputFile.current.disabled = false;
    inputFile.current.click();
  };

  useEffect(() => {
    if (props?.file && props?.file !== undefined && props?.file !== null) {
      setFileUrl(props?.file.url);
      inputFile.current.disabled = true;
      // console.log(`helloo`)
      // urlToObject(props?.file.url);
    } else setFileUrl(null); //incase if file is undefined after uploading once
    // console.log(`helloo`,props?.file)
  }, [props?.file]);

  return (
    <div
      style={{ width: `max-content` }}
      onMouseEnter={() => {
        if (fileUrl !== null && fileUrl !== undefined) {
          setShowEdit(true);
        }
      }}
      onMouseLeave={() => {
        if (fileUrl !== null && fileUrl !== undefined) {
          setShowEdit(false);
        }
      }}
    >
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        {fileUrl !== null && fileUrl !== undefined && (
          <Canvas camera={{ position: [0, 0, 0] }}>
            <Suspense fallback={null}>
              <StlViewer url={fileUrl} />
            </Suspense>
            <CameraController />
          </Canvas>
        )}
        <button
          onClick={() => setOpen(false)}
          style={{
            position: `relative`,
            bottom: `47%`,
            right: `2%`,
            border: `none`,
            borderRadius: `10px`,
            cursor: `pointer`,
            padding: `7px 10px`,
          }}
        >
          <FontAwesomeIcon icon={faTimes} color="#4A4A4A" size="1x" />
        </button>
      </Backdrop> */}
      <input
        // accept="image/*"
        ref={inputFile}
        id={props?.id}
        type="file"
        name={props?.name}
        multiple={props?.multiple}
        onChange={onChangeHandler}
        hidden
      />
      <label htmlFor={props?.name} onClick={onLabelClick}>
        <div
          style={{
            borderRadius: `10px`,
            boxShadow: `0 2px 60px rgb(40 40 40 / 8%)`,
            width: `192px`,
            height: `180px`,
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            gap: `2rem`,
            padding: `16px`,
            background: `#fff`,
            cursor: `pointer`,
          }}
        >
          <a
            href={fileUrl}
            ref={downloadAnchor}
            download
            style={{ textDecoration: "none" }}
          >
            <div
              style={{
                width: `160px`,
                height: `128px`,
                border: `1px dashed rgb(204, 204, 204)`,
                boxSizing: `border-box`,
                borderRadius: `10px`,
                padding: `15px`,
                display: `flex`,
                flexDirection: `column`,
                alignItems: `center`,
                justifyContent: `center`,
              }}
              onClick={() => {
                if (fileUrl !== null && fileUrl !== undefined) {
                  //fire download event
                  downloadAnchor.current.click();
                }
              }}
            >
              <div>
                <FontAwesomeIcon
                  icon={faUpload}
                  color={
                    fileUrl !== null && fileUrl !== undefined
                      ? "#347CF3"
                      : "#BFBFBF"
                  }
                  size="3x"
                />
              </div>
            </div>
          </a>
          <div>
            <span>{props?.description}</span>
          </div>
        </div>
      </label>
      <div
        style={{
          position: `relative`,
          bottom: `82%`,
          left: `82%`,
          visibility: !showEdit && `hidden`,
          cursor: `pointer`,
        }}
        onClick={onEdit}
      >
        <div
          style={{
            backgroundColor: `#e3e1e1`,
            borderRadius: `10px`,
            padding: `10px`,
            width: `fit-content`,
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} color="#4A4A4A" size="1x" />
        </div>
      </div>
    </div>
  );
};

// const CameraController = () => {
//   const { camera, gl } = useThree();
//   useEffect(() => {
//     const controls = new OrbitControls(camera, gl.domElement);
//     controls.minDistance = 3;
//     controls.maxDistance = 20;
//     return () => {
//       controls.dispose();
//     };
//   }, [camera, gl]);
//   return null;
// };
