import * as React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  ChangeCurrDiagnosticPlan,
  NewDiagnosticPlan,
  UpdateDiagnosticFormFields,
} from "../../Services/DiagnosticServices";
import Input from "../Input/Input";
import { PhotoUploadField } from "../PhotoUploadField/PhotoUploadField";
import { SingleSTLUploadField } from "../SingleSTLUploadField/SingleSTLUploadField";

import classes from "./Diagnostics.module.css";
import { useState } from "react";
import { useEffect } from "react";
import { GetUser } from "../../Services/GetUsers";

export const Diagnostics = ({
  patient,
  setPatient,
  filteredData,
  setAllData,
}) => {
  const photoFields = [
    { title: "Full Face Smiling", view: "full_face_smiling" },
    { title: "Full Face Not Smiling", view: "full_face_not_smiling" },
    { title: "Profile Not Smiling", view: "profile_not_smiling" },
    { title: "Upper Occlusal", view: "upper_occlusal" },
    { title: "Lower Occlusal", view: "lower_occlusal" },
    { title: "Right Lateral", view: "right_lateral" },
    { title: "Front View", view: "front_view" },
    { title: "Left Lateral", view: "left_lateral" },
  ];

  const xRayFields = [
    { title: "Panoramic", view: "panoramic" },
    { title: "Cephalometric", view: "cephalometric" },
  ];

  const stlFileFields = [
    { title: "Upper Scan", view: "upper_scan" },
    { title: "Lower Scan", view: "lower_scan" },
    { title: "Bite Scan 1", view: "bite1_scan" },
    { title: "Bite Scan 2", view: "bite2_scan" },
  ];

  const questions = [
    "I have bridgework",
    "I have crowns",
    "I have an impacted tooth",
    "I have an implant",
    "I have primary (baby) teeth",
    "I have veeners",
    "Do you feel pain in any of your teeth?",
    "Do you currently have any head, neck or jaw injuries?",
    "Do you have any sores or lumps in or near your mouth?",
    "Do you currently experience jaw clicking, pain, difficulty opening and/or closing or difficulty chewing?",
    "Have you noticed any loosening of your teeth or do you have treated periodontial disease?",
    "Do you have any known allergies to any dental materials?",
    "I have a history of IV bisphosphonate treatment",
    "I am currently on acute corticosteroids or in immunnosuppression, chemotheraphy or radiation of head/neck",
    "I have had a bone marrow transplant or treatment of hematological malignancies (blood cancers) within the past 2 years",
  ];

  const [threeDFileBeingUploaded, setThreeDFileBeingUploaded] =
    React.useState(``);

  const [treatmentType, setTreatmentType] = useState(
    patient?.diagnostic?.treatment_type || ""
  );

  const dateInParts = patient?.birthdate.split("/");
  const [birthdate, setBirthdate] = useState(
    patient?.birthdate === true
      ? new Date(`${dateInParts[2]}-${dateInParts[1]}-${dateInParts[0]}`)
      : new Date()
  );

  const [gender, setGender] = useState(
    patient?.gender !== "" ? patient?.gender : "Male"
  );

  const [questionnaire, setQuestionnaire] = useState({
    1: `No`,
    2: `No`,
    3: `No`,
    4: `No`,
    5: `No`,
    6: `No`,
    7: `No`,
    8: `No`,
    9: `No`,
    10: `No`,
    11: `No`,
    12: `No`,
    13: `No`,
    14: `No`,
    15: `No`,
  });

  const [diagnosticPlans, setDiagnosticPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState();

  const updateDiagnosticField = async (
    fieldName,
    data,
    type = "",
    view = ""
  ) => {
    const formData = new FormData();
    formData.append("User_id", patient.user_id);
    formData.append(fieldName, data);
    if (type !== "") {
      formData.append("type", type);
      formData.append("view", view);
    } else {
      formData.append("type", fieldName);
    }
    let response;
    if (type === "3d_files") {
      setThreeDFileBeingUploaded(view);
    } else if (type === "treatment_details") {
      Object.values(questionnaire).forEach((val, index) => {
        if (index + 1 !== fieldName) {
          formData.append(index + 1, val);
        } else {
          console.log(fieldName, index + 1);
        }
      });
    }
    try {
      response = await UpdateDiagnosticFormFields(formData);
      if (type === "3d_files") {
        setThreeDFileBeingUploaded(``);
      }
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    console.log(response);
  };

  const onGenderChange = (e) => {
    setGender(e.target.value);
    updateDiagnosticField("gender", e.target.value);
  };

  const onBirthdateChange = (date) => {
    setBirthdate(date);
    updateDiagnosticField("birthdate", date.toDateString());
  };

  const onTreatmentTypeChange = (e) => {
    setTreatmentType(e.target.value);
    updateDiagnosticField("treatment_type", e.target.value);
  };

  const onQuestionnaireChange = (id, e) => {
    console.log(id, e.target.value);
    setQuestionnaire((prev) => {
      const newVal = { ...prev };
      newVal[id] = e.target.value;
      return newVal;
    });
    updateDiagnosticField(id, e.target.value, "treatment_details");
  };
  console.log(patient);

  const addDiagnosticPlan = () => {
    const d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const formData = new FormData();
    formData.append("User_id", patient?.user_id);
    // formData.append("Created_time", `${createdTime} ${time}`);
    NewDiagnosticPlan(formData).then((res) => {
      console.log(res.data.dpid);
      GetUser({ user_id: patient?.user_id }).then((res) => {
        setPatient(res.data);
        setDiagnosticPlans(res.data.diagnostic?.diagnostic_snapshots);
        res.data.diagnostic?.diagnostic_snapshots.forEach((plan) => {
          if (plan.plan_id === res.data.current_diagnostic_selected) {
            setSelectedPlan(plan);
            setPatient(res?.data);
          }
        });
        const updatedUsers = filteredData.filter(
          (user) => user.user_id !== patient.user_id
        );
        setAllData([...updatedUsers, res.data]);
      });
    });
  };

  const changePlan = (changedPlanId) => {
    // const currentPlan = patient?.treatment_plans?.filter(
    //   (plan) => plan?.plan_id === changedPlanId
    // )[0];
    const formData = new FormData();
    formData.append("User_id", patient?.user_id);
    formData.append("dp_id", changedPlanId);
    ChangeCurrDiagnosticPlan(formData).then((res) => {
      GetUser({ user_id: patient.user_id }).then((res) => {
        const plan = res?.data?.diagnostic?.diagnostic_snapshots?.filter(
          (plan) => plan?.plan_id === changedPlanId
        )[0];
        setSelectedPlan(plan);
        setPatient(res.data);
      });
    });
  };

  useEffect(() => {
    const currentPlan = patient?.diagnostic?.diagnostic_snapshots?.filter(
      (plan) => plan?.plan_id === patient?.current_diagnostic_selected
    )[0];
    setDiagnosticPlans(
      patient?.diagnostic?.diagnostic_snapshots
        ? patient?.diagnostic?.diagnostic_snapshots
        : []
    );
    setSelectedPlan(currentPlan ? currentPlan : {});
    console.log(currentPlan);

    setQuestionnaire(() => {
      let initialVal = {};
      patient?.diagnostic?.pre_existing_conditions.forEach((val, index) => {
        if (index !== 0) initialVal[index] = val;
      });
      console.log(initialVal);
      return initialVal;
    });

    if (patient?.diagnostic?.treatment_type !== "")
      setTreatmentType(patient?.diagnostic?.treatment_type);
    if (patient?.gender) setGender(patient?.gender);
    if (patient?.birthdate) {
      const dateInParts = patient?.birthdate.split("/");
      setBirthdate(
        new Date(`${dateInParts[2]}-${dateInParts[1]}-${dateInParts[0]}`)
      );
    }
  }, [patient]);

  return (
    <>
      <ToastContainer />
      <div style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}>
        <div className={classes.inputs}>
          <label htmlFor="birthdate">Birthdate</label>
          <DatePicker selected={birthdate} onChange={onBirthdateChange} />
        </div>
        <div className={classes.inputs}>
          <label htmlFor="gender">Gender</label>
          <div>
            <NativeSelect
              onChange={onGenderChange}
              inputProps={{
                name: "change-gender",
                id: "uncontrolled-native",
              }}
              // defaultValue={}
              value={gender}
              // sx={{ width: `30%` }}
            >
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
              <option value={"Other"}>Other</option>
            </NativeSelect>
          </div>
        </div>
        <div className={classes.inputs}>
          <label htmlFor="chief-complaint">Chief Complaint</label>
          <Input
            name="chief-complaint"
            // autoFocus={true}
            onSubmit={updateDiagnosticField}
            value={patient?.diagnostic?.chief_complaint}
          />
        </div>
        <div className={classes.inputs}>
          <label htmlFor="prescription-notes">Prescription Notes</label>
          <Input
            name="prescription-notes"
            multiline={true}
            onSubmit={updateDiagnosticField}
            value={patient?.diagnostic?.prescription_notes}
          />
        </div>
        <div className={classes.inputs}>
          <label>Treatment Type</label>
          <FormControl>
            <NativeSelect
              onChange={onTreatmentTypeChange}
              inputProps={{
                name: "change-treatment-type",
                id: "uncontrolled-native",
              }}
              // defaultValue={}
              value={treatmentType}
              // sx={{ width: `30%` }}
            >
              <option value={""}>Select Treatment Type</option>
              <option value={"Upper"}>Upper</option>
              <option value={"Lower"}>Lower</option>
              <option value={"Both"}>Both</option>
            </NativeSelect>
          </FormControl>
        </div>
        <div style={{ display: `flex`, flexDirection: `column`, gap: `1rem` }}>
          {questions.map((question, id) => (
            <FormControl className={classes.questions}>
              <label
                className={classes.questionLabel}
                id="demo-radio-buttons-group-label"
              >
                {question}
              </label>
              <RadioGroup
                className={classes.questionOptions}
                aria-labelledby="demo-radio-buttons-group-label"
                // defaultValue="No"
                value={questionnaire[`${id + 1}`]}
                onChange={(e) => {
                  console.log(questionnaire[`${id + 1}`], questionnaire);
                  onQuestionnaireChange(id + 1, e);
                }}
                name="radio-buttons-group"
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          ))}
        </div>
        <div style={{ marginTop: `4rem` }}>
          <h2>Diagnostic Plans</h2>
          <div className={classes.changePlan}>
            <Button onClick={addDiagnosticPlan} variant="contained">
              New Diagnostic Plan
            </Button>
            <FormControl style={{ marginLeft: "30px" }}>
              <NativeSelect
                onChange={(e) => changePlan(e.target.value)}
                defaultValue={selectedPlan?.plan_id}
                value={selectedPlan?.plan_id}
                inputProps={{
                  name: "change diagnostic plan",
                  id: "uncontrolled-native",
                }}
              >
                {diagnosticPlans?.length === 0 ? (
                  <option value="no-tp">No Diagnostic Plan Available</option>
                ) : (
                  ""
                )}
                {diagnosticPlans?.map((plan) => (
                  <>
                    {selectedPlan === {} ? (
                      <option value="no-plan">No Plan Selected</option>
                    ) : (
                      ""
                    )}
                    <option value={plan.plan_id}>
                      {selectedPlan.plan_id === plan.plan_id
                        ? `Current Plan (${plan.plan_id})`
                        : `Diagnostic Plan ${plan.plan_id}`}
                    </option>
                  </>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          {diagnosticPlans?.length !== 0 && (
            <div>
              <div>
                <h3>Patient Photos</h3>
                <div className={classes.photoFields}>
                  {photoFields.map((photo) => (
                    <PhotoUploadField
                      description={photo.title}
                      name={photo.view}
                      id={photo.view}
                      fieldName="initial_photos"
                      view={photo.view}
                      onChange={updateDiagnosticField}
                      file={selectedPlan?.initial_photos?.find(
                        (initial_photo) => initial_photo.view === photo.view
                      )}
                    />
                  ))}
                </div>
              </div>
              <div>
                <h3>X-Ray Images</h3>
                <div className={classes.photoFields}>
                  {xRayFields.map((photo) => (
                    <PhotoUploadField
                      description={photo.title}
                      name={photo.view}
                      id={photo.view}
                      fieldName="xray_files"
                      view={photo.view}
                      onChange={updateDiagnosticField}
                      file={
                        selectedPlan &&
                        selectedPlan?.xray_files?.find(
                          (xray_file) => xray_file.view === photo.view
                        )
                      }
                    />
                  ))}
                </div>
              </div>
              <div>
                <h3>3D Files</h3>
                <div className={classes.photoFields}>
                  {stlFileFields.map((stl) => (
                    <>
                      <SingleSTLUploadField
                        description={stl.title}
                        name={stl.view}
                        id={stl.view}
                        fieldName="3d_files"
                        view={stl.view}
                        onChange={updateDiagnosticField}
                        file={
                          selectedPlan &&
                          selectedPlan["3d_files"] !== undefined &&
                          selectedPlan["3d_files"] !== null &&
                          selectedPlan["3d_files"] !== [] &&
                          selectedPlan["3d_files"].find(
                            (file) => file.view === stl.view
                          )
                        }
                      />
                      <div
                        style={{
                          position: `relative`,
                          right: `150px`,
                          top: `60px`,
                        }}
                      >
                        {threeDFileBeingUploaded === stl.view ? (
                          <CircularProgress />
                        ) : null}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
