import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { GetUser } from "../../Services/GetUsers";
import { DeleteFile, UploadFile } from "../../Services/TreatmentService";
import { PhotoUploadField } from "../PhotoUploadField/PhotoUploadField";
import styles from "./FileUpload.module.css";
function FileUpload(props) {
  const [isUploaded, setIsUploaded] = useState(true);
  const inputRef = React.createRef(null);

  const handleClick = () => {
    inputRef.current.value = null;
    inputRef.current.click();
  };

  const deleteFile = (file) => {
    let d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    const formData = new FormData();
    formData.append("User_id", props.userId);
    formData.append("modified_time", `${createdTime} ${time}`);
    formData.append("type", props.type);
    formData.append("stage", "Treatment");
    formData.append("filename", file);
    DeleteFile(formData).then((res) => {
      console.log(res);
      GetUser({ user_id: props.userId }).then((res) => {
        const plan = res?.data?.treatment_plans?.filter(
          (plan) => plan?.plan_id === props?.selectedPlan?.plan_id
        )[0];
        props.setSelectedPlan(plan);
        props.setPatient(res.data);
      });
    });
  };

  const handleUpload = (event) => {
    setIsUploaded(false);

    let d = new Date();
    let createdTime = `${
      d.getDate().toString().length === 1 ? "0" + d.getDate() : d.getDate()
    }/${d.getMonth() + 1}/${d.getFullYear()}`;
    let time = d.toLocaleString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    let files = Array.from(event.files);
    const formData = new FormData();
    formData.append("User_id", props.userId);
    formData.append("type", props.type);
    formData.append("modified_time", `${createdTime} ${time}`);
    files.forEach((file) => {
      formData.append("3d_files", file);
    });

    console.log(event.files);
    console.log(event.files[0].name.split("_"));

    UploadFile(formData)
      .then((res) => {
        setIsUploaded(true);
        GetUser({ user_id: props?.userId }).then((res) => {
          const plan = res?.data?.treatment_plans?.filter(
            (plan) => plan?.plan_id === props?.selectedPlan?.plan_id
          )[0];
          console.log(plan);
          props?.setSelectedPlan({ ...plan });
          props.setPatient(res.data);
        });
        props?.setNumUpper(res.data.upper_aligner);
        props?.setNumLower(res.data.lower_aligner);
      })
      .catch((err) => setIsUploaded(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <b>{props.for}</b>
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={(e) => handleUpload(e.target)}
          multiple
        />
        <Button onClick={handleClick} variant="contained">
          Upload
        </Button>
      </div>
      <div className={styles.fileContainer}>
        {props.selectedPlan?.stl?.map((stlFile, index) => {
          let filename = stlFile?.location?.split("/");
          filename = filename[filename.length - 1];
          return props?.view === stlFile?.view ? (
            <div id={index} className={styles.fileBox}>
              <a href={stlFile?.url} className={styles.fileName}>
                {filename?.length > 20
                  ? filename.slice(0, 20) + "..."
                  : filename}
              </a>
              <FontAwesomeIcon
                onClick={() => deleteFile(filename)}
                icon={faTimes}
                color="#174EA6"
                style={{ cursor: "pointer" }}
                size="sm"
              />
            </div>
          ) : (
            ""
          );
        })}

        {!isUploaded ? <CircularProgress /> : ""}
      </div>
    </div>
  );
}

export default FileUpload;
