import request from "./API";

export const GetAllUsers = (params, signal) => {
  return request.get(
    `/admin-form/users?limit=${params.limit}&nextToken=${params.nextToken}&stage=${params.stage}&substage=${params.subStage}&sort_order=${params.sortBy}&sort_by=${params.sortScanTime}&search=${params.search}&search_phone=${params.searchPhone}&scan_time_filter_start=${params.scanTimeFilter.start}&scan_time_filter_end=${params.scanTimeFilter.end}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    }
  );
};
export const GetAssignedUsers = (params, signal) => {
  console.log(params);
  return request.get(
    `/admin-form/assigned-users?limit=${params.limit}&nextToken=${params.nextToken}&stage=${params.stage}&substage=${params.subStage}&sort_order=${params.sortBy}&sort_by=${params.sortScanTime}&search=${params.search}&search_phone=${params.searchPhone}&scan_time_filter_start=${params.scanTimeFilter.start}&scan_time_filter_end=${params.scanTimeFilter.end}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    }
  );
};
export const GetUser = (data) => {
  return request.get(`/admin-form/user/${data.user_id}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const GetUsersByRole = (userType) => {
  return request.get(`/admin-form/users/${userType}`);
};

export const GetAdminUsers = () => {
  return request.get(`/admin-form/admin-users`);
};

export const UpdateAdmin = (data) => {
  return request.post(`/admin-form/admin-users/profile`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
