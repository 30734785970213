import request from "./API";

export const UpdateDiagnosticFormFields = (data) => {
  return request.post("/admin-form/diagnostic/update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const NewDiagnosticPlan = (data) => {
  return request.post("/users/diagnostic", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const ChangeCurrDiagnosticPlan = (data) => {
  return request.post("/users/diagnostic/change", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
