import React, { createContext, useState, useEffect } from "react";
import { IsAuthenticated } from "../Services/AuthService";

export const AuthContext = createContext();

const Auth = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAuthDone, setIsAuthDone] = useState(false);

  useEffect(() => {
    // Even if refreshed, this is the first api call that is going to happen.

    if (localStorage.getItem("user")) {
      setUser(JSON.parse(localStorage.getItem("user")));
      setIsAuthenticated(true);
      setIsLoaded(true);
    }
    IsAuthenticated()
      .then((data) => {
        console.log("isAuth :", data);
        if (data.isAuthenticated) {
          console.log("Updated Auth Check - ", data);
          setUser(data.admin_user);
          setIsAuthenticated(data.isAuthenticated);
          localStorage.setItem("user", JSON.stringify(data.admin_user));
          setIsLoaded(true);
        } else {
          console.log("There is some error, please login again. ");
          setIsLoaded(true);
          setIsAuthenticated(false);
          localStorage.removeItem("user");
          setUser(null);
        }
      })
      .catch((err) => {
        console.dir("console err:", err);
        console.log("There is some error, please login again. ");
        setIsLoaded(true);
        setIsAuthenticated(false);
        localStorage.removeItem("user");
        setUser(null);
      });
  }, [isAuthDone]);

  return (
    <div>
      {!isLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            background: "white",
          }}
        ></div>
      ) : (
        <AuthContext.Provider
          value={{
            user,
            setUser,
            isAuthenticated,
            setIsAuthenticated,
            setIsAuthDone,
            isAuthDone,
          }}
        >
          {children}
        </AuthContext.Provider>
      )}
    </div>
  );
};

export default Auth;
