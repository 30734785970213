import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { GetAllUsers, GetUser } from "../../Services/GetUsers";
import { PostComment } from "../../Services/JournalService";
import sidebarOptions from "../Sidebar/sidebarData";
import styles from "./Journal.module.css";
function Journal({ patient, setAllData,onUserUpdateHandler }) {
  const [comment, setComment] = useState("");

  const handleSubmit = (e) => {
    console.log(patient.user_id);
    e.preventDefault();
    PostComment(patient.user_id, { comment: comment }).then(() => {
      setComment("");
      GetUser({ user_id:patient?.user_id }).then((res) => onUserUpdateHandler(res.data));
    });
  };

  return (
    <div className={styles.container}>
      <form className={styles.commentBox} onSubmit={(e) => handleSubmit(e)}>
        <TextField
          hiddenLabel
          id="comments"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          rows={4}
          style={{ width: "80%" }}
          placeholder="Type your message ..."
          multiline
          required
        />
        <Button
          variant="contained"
          type="submit"
          style={{ marginLeft: "20px" }}
        >
          Comment
        </Button>
      </form>
      <div className={styles.journalUpdates}>
        {patient.journal
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((updates) => {
            if (updates.event_type === "Stage_Updated") {
              let date = new Date(updates.date);

              return (
                <div className={styles.updateBox}>
                  <div className={styles.labelBox}>
                    <h3 style={{ marginBottom: "5px", marginTop: "0px" }}>
                      Stage updated to: &nbsp;
                    </h3>
                    <h5
                      className={styles.noBold}
                      style={{ marginTop: "1px", color: "grey" }}
                    >
                      {date.getDate() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getFullYear()}
                    </h5>
                  </div>
                  <i
                    className={styles.noBold}
                    style={{
                      color: sidebarOptions.find(
                        (stageObject) => stageObject.name === updates.result
                      )?.color,
                      fontSize: "18.72px",
                    }}
                  >
                    {updates.result}
                  </i>
                </div>
              );
            } else if (updates.event_type === "Comment") {
              let date = new Date(updates.date);
              return (
                <div className={styles.updateBox}>
                  <div className={styles.labelBox}>
                    <h3 style={{ marginBottom: "5px", marginTop: "0px" }}>
                      Admin commented :&nbsp;&nbsp;
                    </h3>
                    <h5
                      className={styles.noBold}
                      style={{ marginTop: "1px", color: "grey" }}
                    >
                      {date.getDate() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getFullYear()}
                    </h5>
                  </div>
                  <i className={styles.noBold} style={{ fontSize: "18.72px" }}>
                    {updates.result}
                  </i>
                </div>
              );
            } else if (updates.event_type === "substage-updated") {
              let date = new Date(updates.date);

              return (
                <div className={styles.updateBox}>
                  <div className={styles.labelBox}>
                    <h3 style={{ marginBottom: "5px", marginTop: "0px" }}>
                      Sub Stage updated to: &nbsp;
                    </h3>
                    <h5
                      className={styles.noBold}
                      style={{ marginTop: "1px", color: "grey" }}
                    >
                      {date.getDate() +
                        "/" +
                        (date.getMonth() + 1) +
                        "/" +
                        date.getFullYear()}
                    </h5>
                  </div>
                  <i
                    className={styles.noBold}
                    style={{
                      color: sidebarOptions.find(
                        (stageObject) => stageObject.name === updates.result
                      )?.color,
                      fontSize: "18.72px",
                    }}
                  >
                    {updates.result}
                  </i>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}

export default Journal;
