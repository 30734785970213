import React, { useContext, useState } from "react";
import {
  Button,
  Input,
  InputAdornment,
  InputBase,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import headerStyle from "./Header.module.css";
import { useHistory } from "react-router-dom/";
import sidebarOptions from "../Sidebar/sidebarData";
import { AuthContext } from "../../context/AuthContext";
import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
} from "@mui/material";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import {
  faPlus,
  faPlusCircle,
  faTimes,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const subStagesScanBooked = [
  "Scan Booked",
  "Scan booked but not done",
  "Not interested",
  "Scan Done",
  "Scan Done at 49k",
  "Scan Done at 69k",
];

const subStagesPrepareTP = [
  "Prepare TP",
  "Prepare TP-Priority",
  "Need more info",
  "On Hold",
  "TP Shared",
  "Rejected",
];

const subStagesTPReview = [
  "Pending",
  "Accepted",
  "On Hold",
  "Revision Plan",
  "Rejected",
];

const subStagesTPOut = [
  "TP Consultation",
  "Revise Plan",
  "Not Interested",
  "Interested Later",
  "Negotiation",
  "Price Too High",
  "Lost to competition",
  "Plan not good",
];

const subStagesInTreatment = [
  "Ongoing",
  "Review Progress",
  "Remove Attachment",
];

const subStagesResolutionRequired = [
  "Case Review",
  "Pending Scan",
  "Treatment Planning",
  "Review TP",
  "TP Consultation",
  "Manufacturing",
  "Delivery",
  "Resolved",
  "Rejected",
];

const subStagesLost = ["Lost", "Competition", "Pricing", "Treatment Plan"];

const stageSubStageMapping = {
  "Scan Booked": subStagesScanBooked,
  "TP in Progress": subStagesPrepareTP,
  "Review TP": subStagesTPReview,
  "TP Approved": subStagesTPOut,
  "In Treatment": subStagesInTreatment,
  "Resolution Required": subStagesResolutionRequired,
  Lost: subStagesLost,
};

function Header(props) {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  // console.log(currentSubStage)
  const onSubStageChange = (subStage) => {
    props.onSubStageChange(subStage);
    props.setCurrentSubStage(subStage);
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [filterScanTimeBy, setFilterScanTimeBy] = useState("Today");
  const [isScanTimeFilterApplied, setIsScanTimeFilterApplied] = useState(false);

  const onScanTimeFilterApply = () => {
    console.log("Hey");
    props.onScanFilterApply(
      startDate.toISOString().slice(0, 10),
      endDate !== null &&
        endDate.getDate() > startDate.getDate() &&
        endDate.getMonth() >= startDate.getMonth() &&
        endDate.getFullYear() >= startDate.getFullYear()
        ? endDate.toISOString().slice(0, 10)
        : ""
    );
    setIsScanTimeFilterApplied(true);
    console.log(
      endDate!==null &&
      endDate.getDate() > startDate.getDate() &&
        endDate.getMonth() >= startDate.getMonth() &&
        endDate.getFullYear() >= startDate.getFullYear()
        ? endDate.toISOString().slice(0, 10)
        : ""
    );
  };

  const onScanTimeFilterRemove = () => {
    props.onScanFilterApply();
    setIsScanTimeFilterApplied(false);
    setStartDate(new Date());
    setEndDate(null);
  };

  const hasSubStages = (stage) => {
    switch (stage) {
      case "Scan Booked":
      case "TP in Progress":
      case "Review TP":
      case "TP Approved":
      case "In Treatment":
      case "Resolution Required":
      case "Lost":
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Dialog
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "50%",
            maxHeight: 300,
          },
        }}
        fullWidth={true}
        onClose={() => {
          setIsFilterModalOpen(false);
        }}
        open={isFilterModalOpen}
      >
        <DialogTitle>Set Filter</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "2rem",
              flexWrap: "wrap",
            }}
          >
            <Typography>Scan Time</Typography>
            <Box>
              <NativeSelect
                onChange={(e) => setFilterScanTimeBy(e.target.value)}
                inputProps={{
                  name: "change-sub-stage",
                  id: "uncontrolled-native",
                }}
                value={filterScanTimeBy}
              >
                <option value="Today">Today</option>
                <option value="Between">Between</option>
              </NativeSelect>
            </Box>
            {filterScanTimeBy === "Between" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <div>
                  {/* <span style={{ color: "#636363" }}>Start Date</span> */}
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                  />
                </div>
                <div>
                  <span>-</span>
                </div>
                <div>
                  {/* <span style={{ color: "#636363" }}>End Date</span> */}
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                  />
                </div>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Button variant="contained" onClick={onScanTimeFilterApply}>
                Apply
              </Button>
              {isScanTimeFilterApplied && (
                <Button variant="contained" onClick={onScanTimeFilterRemove}>
                  Remove
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <div
        className={headerStyle.headerContainer}
        style={{ marginTop: "10px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "12px",
              height: "12px",
              backgroundColor: sidebarOptions.find(
                (stageObject) => stageObject.name === props.status
              )?.color,
              borderRadius: "50%",
              marginRight: "10px",
            }}
          ></div>
          <h2
            style={{
              color: "#46454d",
              fontWeight: "normal",
              width: `max-content`,
            }}
          >
            {props.status}
          </h2>
        </div>
        <div className={headerStyle.subContainer}>
          <Input
            id="input-with-icon-adornment"
            value={props.searchVal}
            onChange={(e) => props.setSearch(e.target.value)}
            placeholder="Search"
            style={{
              backgroundColor: "#f4f4f4",
              border: "1px solid #e9eaea",
              padding: "6px",
              flex: "0.8",
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <div style={{ display: `flex`, gap: `1rem` }}>
            {props.newPatient ? (
              <>
              <Button
                variant="outlined"
                onClick={() => history.push("/new-patient")}
              >
                New Patient
              </Button>
              <Button
                variant="outlined"
                onClick={() => history.push("/new-b2b-patient")}
              >
                New B2B Patient
              </Button>
              </>
            ) : (
              ""
            )}
            <div style={{ textAlign: "center" }}>
              <AccountCircle
                onClick={() =>
                  history.push(
                    `/${user?.admin_user_type}/${user?.admin_user_id}`
                  )
                }
                style={{ fontSize: "30px", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={headerStyle.headerContainer}
        style={{ marginTop: "10px", justifyContent: "center", gap: "2rem" }}
      >
        {hasSubStages(props.status) && (
          <FormControl>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Select Sub Stage
            </InputLabel>
            <NativeSelect
              onChange={(e) => onSubStageChange(e.target.value)}
              inputProps={{
                name: "change-sub-stage",
                id: "uncontrolled-native",
              }}
              defaultValue={props.currentSubStage}
              value={props.currentSubStage}
            >
              <option value="All Sub Stages">All Sub Stages</option>
              {stageSubStageMapping[props.status].map((val) => (
                <option value={val}>{val}</option>
              ))}
            </NativeSelect>
          </FormControl>
        )}
        {
          <div>
            <Button
              variant="outlined"
              onClick={() => setIsFilterModalOpen(true)}
            >
              Filter
            </Button>
          </div>
        }
        {/* {props.status==="Scan Booked" && <div>
          <span style={{ color: "#636363" }}>Start Date</span>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>}
        {props.status==="Scan Booked" && <div>
          <span style={{ color: "#636363" }}>End Date</span>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>} */}
      </div>
    </>
  );
}

export default Header;
