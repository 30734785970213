import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Treatment from "../Treatment/Treatment";
import PatientFiles from "../PatientFiles/PatientFiles";
import { Diagnostics } from "../Diagnostics/Diagnostics";
import PaymentData from "../PaymentData/PaymentData2";
import Profile from "../Profile/Profile";
import Journal from "../Journal/Journal";
import PatientBookings from "../PatientBookings/PatientBookings";
import { AuthContext } from "../../context/AuthContext";
import AlignerFeedback from "../AlignerFeedback/AlignerFeedback";
import ReviewScan from "../ReviewScan/ReviewScan";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PatientInfo({
  patient,
  filteredData,
  setAllData,
  setPatient,
  onUserUpdateHandler,
}) {
  const [value, setValue] = React.useState(0);
  const [currentPlan, setCurrentPlan] = React.useState();
  const { user } = React.useContext(AuthContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        // width: "100%",
        backgroundColor: "#ffffff",
        boxShadow: " 1px 2px 30px rgb(0 0 0 / 8%)",
        padding: "10px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Diagnostics" {...a11yProps(0)} />
          <Tab label="Treatment" {...a11yProps(1)} />
          <Tab label="Profile" {...a11yProps(2)} />
          <Tab label="Payment" {...a11yProps(3)} />
          {/* <Tab label="Files" {...a11yProps(4)} /> */}
          <Tab label="Journal" {...a11yProps(4)} />
          <Tab label="Bookings" {...a11yProps(5)} />
          <Tab label="Aligners" {...a11yProps(6)} />
          <Tab label="Scans" {...a11yProps(7)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Diagnostics
          patient={patient}
          filteredData={filteredData}
          setAllData={setAllData}
          setPatient={setPatient}
          setCurrentPlan={setCurrentPlan}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Treatment
          patient={patient}
          filteredData={filteredData}
          setAllData={setAllData}
          setPatient={setPatient}
          setCurrentPlan={setCurrentPlan}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Item Two
      </TabPanel> */}
      <TabPanel value={value} index={2}>
        <Profile
          isAdmin={user?.admin_user_type === "admin" ? true : false}
          patient={patient}
          filteredData={filteredData}
          setAllData={setAllData}
          setPatient={setPatient}
          onUserUpdateHandler={onUserUpdateHandler}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PaymentData patient={patient} currentPlan={currentPlan} />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <PatientFiles patient={patient} />
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        <Journal
          patient={patient}
          setAllData={setAllData}
          onUserUpdateHandler={onUserUpdateHandler}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <PatientBookings
          patient={patient}
          setAllData={setAllData}
          setPatient={setPatient}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <AlignerFeedback
          patient={patient}
          onUserUpdateHandler={onUserUpdateHandler}
        />
      </TabPanel>
      <TabPanel value={value} index={7}>
        <ReviewScan
          patient={patient}
          onUserUpdateHandler={onUserUpdateHandler}
        />
      </TabPanel>
    </Box>
  );
}

export default PatientInfo;
