import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import sidebarOptions from "../Sidebar/sidebarData";
import { useParams } from "react-router-dom";
import { GetAdminUsers } from "../../Services/GetUsers";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "@mui/material";
import Header from "../Header/Header";
import { VerifiedUser } from "@mui/icons-material";

function createData(userId, name, contact, city, email, role) {
  return { userId, name, contact, city, email, role };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));

function AdminUsers(props) {
  //   const { data, searchVal } = props;
  const [order, setOrder] = useState("asc");
  const [rows, setRows] = useState([]);
  const [finalRows, setFinalRows] = useState([]);
  const [search, setSearch] = useState("");

  const { role } = useParams();
  let history = useHistory();
  const { user } = React.useContext(AuthContext);
  useEffect(() => {
    GetAdminUsers().then((res) => {
      const searchedRows = res.data.map((admin) =>
        createData(
          admin?.admin_user_id,
          admin?.staff_name,
          admin?.staff_contact,
          admin?.staff_city,

          admin?.staff_email,
          admin?.admin_user_type
        )
      );
      setRows(searchedRows);
      setFinalRows(searchedRows);
    });
  }, [role]);

  useEffect(() => {
    console.log(search);

    const newRows =
      search !== ""
        ? finalRows?.filter((adminUser) =>
            adminUser?.name?.toLowerCase().includes(search)
          )
        : finalRows;
    setRows(newRows);
  }, [search]);

  const sortByOrder = (property) => {
    const isAsc = order === "asc";
    const sortedRows = rows.sort((a, b) => {
      return isAsc
        ? a[property].toString().toLowerCase() >
          b[property].toString().toLowerCase()
          ? 1
          : -1
        : a[property].toString().toLowerCase() <
          b[property].toString().toLowerCase()
        ? 1
        : -1;
    });
    setRows(sortedRows);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handlePatientClick = (userId, role) => {
    history.push(`/${role}/${userId}`);
  };
  return (
    <div style={{ width: "100%", height: `100vh`, overflowY: `scroll` }}>
      <Header searchVal={search} setSearch={setSearch} />

      <TableContainer component={Paper} style={{ marginTop: "60px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="Patients Table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("name")}
              >
                Patient Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("contact")}
                align="right"
              >
                Contact
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("city")}
                align="right"
              >
                Stage
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("role")}
                align="right"
              >
                Role{" "}
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("email")}
                align="right"
              >
                Email
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <StyledTableRow
                onClick={() => handlePatientClick(row.userId, row.role)}
                key={row.index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.contact}</StyledTableCell>
                <StyledTableCell align="right">{row.city}</StyledTableCell>
                <StyledTableCell align="right">{row.role}</StyledTableCell>
                <StyledTableCell align="right">{row.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default AdminUsers;
