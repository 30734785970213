import Sidebar from "./Components/Sidebar/Sidebar";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Box } from "@mui/material";
import PatientList from "./Pages/PatientList/PatientList";
import { useContext, useEffect, useState } from "react";
import PatientView from "./Pages/PatientView/PatientView";
import NewPatient from "./Pages/NewPatient/NewPatient";
import NewB2BPatient from "./Pages/NewB2BPatient/NewB2BPatient";
import { GetAllUsers, GetAssignedUsers } from "./Services/GetUsers";
import sidebarOptions from "./Components/Sidebar/sidebarData";
import { Signup } from "./Components/Signup/Signup";
import { Login } from "./Components/Login/Login";
import AdminUsers from "./Components/AdminUsers/AdminUsers";
import AdminProfile from "./Components/AdminUsers/AdminProfile";
import { Redirect } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
function App() {
  const [allData, setAllData] = useState([]);
  const { isAuthenticated, setIsAuthenticated, user } = useContext(AuthContext);
  const [userPaginationData, setUserPaginationData] = useState({
    limit: 15,
    offset: 0,
    nextToken:""
  });
  const [shouldUpdateUserList, setShouldUpdateUserList] = useState(true);

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   if (isAuthenticated) {
  //     if (user?.admin_user_type === "admin") {
  //       GetAllUsers(userPaginationData.limit, userPaginationData.offset).then(
  //         (data) => {
  //           if (data.data.end === true) setShouldUpdateUserList(false);
  //           if (shouldUpdateUserList) {
  //             setAllData((prev) => {
  //               console.log(prev, "prev");
  //               console.log(
  //                 "repeat",
  //                 prev.map((obj) => JSON.stringify(obj)).filter((str,i)=>{
  //                   const x = prev.map((obj) => JSON.stringify(obj));
  //                   x[i]="";
  //                   return x.includes(str);
  //                 })
  //               );
  //               const newData = [...prev].concat(data.data.data);
  //               return newData;
  //             });
  //           }
  //         }
  //       );
  //     } else {
  //       console.log("role");
  //       GetAssignedUsers().then((data) => {
  //         setAllData(data.data);
  //       });
  //     }
  //   }

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [isAuthenticated, userPaginationData]);

  const onUserDataRequestHandler = (userPageData) => {
    setUserPaginationData(userPageData);
  };

  const onUserUpdateHandler = (user)=>{
    console.log(user);
    setAllData(prev=>prev.filter(patient=>patient.user_id!==user.user_id).concat(user));
  }

  useEffect(()=>{
    console.log(allData);
  },[allData])

  return (
    <BrowserRouter>
      <Box sx={{ display: "flex", width: "100%" }}>
        {!isAuthenticated ? (
          <>
            <Switch>
              <Route exact path="/">
                <Login setAllData={setAllData} />
              </Route>

              <Redirect to="/" />
            </Switch>
          </>
        ) : (
          <>
            <Sidebar />

            <Switch>
              {user?.admin_user_type === "admin" ? (
                <Route exact path="/register">
                  <Signup />
                </Route>
              ) : (
                ""
              )}
              {sidebarOptions.map((route) => (
                <Route exact path={route.path}>
                  <PatientList
                    name={route.name !== "All Patients" ?route.name:""}
                    // data={
                    //   route.name === "All Patients"
                    //     ? allData
                    //     : allData?.filter(
                    //         (patient) => patient.stage_new.stage === route.name
                    //       )
                    // }
                    totalUserCount={allData?.length}
                    onUserDataRequest={onUserDataRequestHandler}
                  />
                </Route>
              ))}
              <Route exact path="/patient/:id">
                <PatientView filteredData={allData} setAllData={setAllData} onUserUpdateHandler={onUserUpdateHandler}/>
              </Route>
              <Route exact path="/user-management">
                <AdminUsers />
              </Route>
              <Route exact path="/new-patient">
                <NewPatient filteredData={allData} setAllData={setAllData} />
              </Route>
              <Route exact path="/new-b2b-patient">
                <NewB2BPatient filteredData={allData} setAllData={setAllData} />
              </Route>
              <Route exact path="/:role/:id">
                <AdminProfile />
              </Route>
              <Redirect to="/" />
            </Switch>
          </>
        )}
      </Box>
    </BrowserRouter>
  );
}

export default App;
