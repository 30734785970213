import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import sidebarOptions from "../Sidebar/sidebarData";
import { GetBookings } from "../../Services/BookingServices";
import { AuthContext } from "../../context/AuthContext";
import { Button } from "@mui/material";

function createData({
  userId,
  name,
  contact,
  stage,
  email,
  daysInStatus,
  scanTime,
}) {
  // console.log(arguments);
  return { userId, name, contact, stage, email, daysInStatus, scanTime };
}

const composeData = (defaultCreateData, addField) => (x) =>
  addField(defaultCreateData(x));

const addScanDateField = async (data) => {
  console.log(data);
  let newData = null;
  try {
    const response = await GetBookings(data.userId, "at_home");
    const bookings = response.data;
    newData = {
      ...data,
      scanBookingDate: bookings?.[bookings.length - 1]?.timings.start,
    };
  } catch (err) {
    console.log(err);
    newData = data;
  } finally {
    console.log(newData);
    return newData;
  }
};

const addVideoScanDateField = async (data) => {
  console.log(data);
  let newData = null;
  try {
    const response = await GetBookings(data.userId, "video_consultation");
    const bookings = response.data;
    // console.log(response.data, `heyy`);
    newData = {
      ...data,
      videoScanBookingDate: bookings?.[bookings.length - 1]?.timings.start,
    };
  } catch (err) {
    console.log(err);
    newData = data;
  } finally {
    console.log(newData);
    return newData;
  }
};

console.dir(document.head);
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  cursor: "pointer",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
function PatientsTable({
  data,
  searchVal,
  order,
  onSortingOrderToggle,
  onScanDateSortingOrderToggle,
  stage,
  subStage,
}) {
  // const [order, setOrder] = useState("asc");
  const [rows, setRows] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  let history = useHistory();
  console.log(rows);
  const { ref, inView, entry } = useInView();

  const { isAuthenticated, setIsAuthenticated, user } =
    React.useContext(AuthContext);

  useEffect(() => {
    if (data?.length) {
      const searchedRowsPromises = searchVal
        ? data
          ?.filter((patient) => {
            let fullname =
              patient?.firstname +
              " " +
              (patient?.lastname !== undefined ? patient?.lastname : "");
            return fullname?.includes(searchVal);
          })
          ?.map(async (patient) => {
            if (
              user?.admin_user_type !== "scanning_doctor" &&
              user?.admin_user_type !== "sales"
            ) {
              return createData({
                userId: patient?.user_id,
                name:
                  (patient?.firstname !== undefined
                    ? patient?.firstname
                    : "") +
                  " " +
                  (patient?.lastname !== undefined ? patient?.lastname : ""),
                contact: patient?.contact,
                stage: patient?.stage_new.stage,
                email: patient?.email,
                daysInStatus: patient?.stage_new.days_in_status,
                scanTime: subStage === "Scan Booked" ? patient?.scan_time : stage === "Doctor Consultation" ? patient?.consultation_time : undefined,
              });
            } else if (user?.admin_user_type === "scanning_doctor") {
              return await createData({
                userId: patient?.user_id,
                name:
                  (patient?.firstname !== undefined
                    ? patient?.firstname
                    : "") +
                  " " +
                  (patient?.lastname !== undefined ? patient?.lastname : ""),
                contact: patient?.contact,
                stage: patient?.stage_new.stage,
                email: patient?.email,
                daysInStatus: patient?.stage_new.days_in_status,
                scanTime: patient?.scan_time,
              });
            } else if (user?.admin_user_type === "sales") {
              return await createData({
                userId: patient?.user_id,
                name:
                  (patient?.firstname !== undefined
                    ? patient?.firstname
                    : "") +
                  " " +
                  (patient?.lastname !== undefined ? patient?.lastname : ""),
                contact: patient?.contact,
                stage: patient?.stage_new.stage,
                email: patient?.email,
                daysInStatus: patient?.stage_new.days_in_status,
                scanTime: patient?.consultation_time,
              });
            }
          })
        : data?.map(async (patient) => {
          if (
            user?.admin_user_type !== "scanning_doctor" &&
            user?.admin_user_type !== "sales"
          ) {
            console.log(subStage === "Scan Booked" ? patient?.scan_time : stage === "Doctor Consultation" ? patient?.consultation_time : undefined
            )
            return createData({
              userId: patient?.user_id,
              name:
                (patient?.firstname !== undefined ? patient?.firstname : "") +
                " " +
                (patient?.lastname !== undefined ? patient?.lastname : ""),
              contact: patient?.contact,
              stage: patient?.stage_new.stage,
              email: patient?.email,
              daysInStatus: patient?.stage_new.days_in_status,
              scanTime: subStage === "Scan Booked" ? patient?.scan_time : stage === "Doctor Consultation" ? patient?.consultation_time : undefined,
            });
          } else if (user?.admin_user_type === "scanning_doctor") {
            return await createData({
              userId: patient?.user_id,
              name:
                (patient?.firstname !== undefined ? patient?.firstname : "") +
                " " +
                (patient?.lastname !== undefined ? patient?.lastname : ""),
              contact: patient?.contact,
              stage: patient?.stage_new.stage,
              email: patient?.email,
              daysInStatus: patient?.stage_new.days_in_status,
              scanTime: patient?.scan_time,
            });
          } else if (user?.admin_user_type === "sales") {
            return await createData({
              userId: patient?.user_id,
              name:
                (patient?.firstname !== undefined ? patient?.firstname : "") +
                " " +
                (patient?.lastname !== undefined ? patient?.lastname : ""),
              contact: patient?.contact,
              stage: patient?.stage_new.stage,
              email: patient?.email,
              daysInStatus: patient?.stage_new.days_in_status,
              scanTime: patient?.consultation_time,
            });
          }
        });
      Promise.all(searchedRowsPromises).then((searchedRows) => {
        // const sortedRows = searchedRows.sort((a, b) =>
        // order==="ascending" ? a["daysInStatus"] - b["daysInStatus"] : b["daysInStatus"] - a["daysInStatus"]
        // );
        setRows(searchedRows);
      });
    }
  }, [searchVal, data]);

  // useEffect(() => {
  //   console.log(inView, entry, data);
  //   if (inView === true && data && data.length !== 0) {
  //     setIsLoadingData(true);
  //     props.onUserDataRequest({
  //       limit: 15,
  //       offset: props.totalUserCount,
  //     });
  //   }
  // }, [inView, data]);

  useEffect(() => {
    setIsLoadingData(false);
  }, [data]);

  const sortByOrder = (property) => {
    // const sortedRows = rows.sort((a, b) => {
    //   console.log(a[property],b[property]);
    //   return isAsc
    //     ? a[property].toString() >
    //       b[property].toString()
    //       ? 1
    //       : -1
    //     : a[property].toString() <
    //       b[property].toString()
    //     ? 1
    //     : -1;
    // });
    console.log(order);
    // const sortedRows = rows.sort((a, b) =>
    //   order==="ascending" ? a[property] - b[property] : b[property] - a[property]
    // );
    // console.log(rows,sortedRows);
    // setRows(sortedRows);
    onSortingOrderToggle();
  };

  const handlePatientClick = (userId) => {
    history.push(`/patient/${userId}`);
  };
  if (data?.length === 0 || data === null || data === undefined)
    return (
      <h1 style={{ textAlign: "center", marginTop: "30px" }}>
        No Patients Available
      </h1>
    );
  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: "60px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="Patients Table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              // onClick={() => sortByOrder("name")}
              >
                Patient Name
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                // onClick={() => sortByOrder("contact")}
                align="right"
              >
                Contact
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                // onClick={() => sortByOrder("stage")}
                align="right"
              >
                Stage
              </TableCell>
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                onClick={() => sortByOrder("daysInStatus")}
                align="right"
              >
                Days in status
              </TableCell>
              {(user?.admin_user_type === "scanning_doctor" ||
                user?.admin_user_type === "admin") &&
                subStage === "Scan Booked" && (
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => onScanDateSortingOrderToggle()}
                    align="right"
                  >
                    Scan Date
                  </TableCell>
                )}
              {(user?.admin_user_type === "sales" ||
                user?.admin_user_type === "admin") &&
                stage === "Doctor Consultation" && (
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => onScanDateSortingOrderToggle()}
                    align="right"
                  >
                    Video Consultation Date
                  </TableCell>
                )}
              <TableCell
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                // onClick={() => sortByOrder("email")}
                align="right"
              >
                Email
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <StyledTableRow
                onClick={() => handlePatientClick(row.userId)}
                key={row.index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.contact}</StyledTableCell>
                <StyledTableCell align="right">
                  <div
                    style={{
                      fontWeight: "bold",
                      color: sidebarOptions.find(
                        (stageObject) => stageObject.name === row.stage
                      )?.color,
                    }}
                  >
                    {row.stage}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.daysInStatus}
                </StyledTableCell>
                {(user?.admin_user_type === "scanning_doctor" ||
                  user?.admin_user_type === "admin") &&
                  subStage === "Scan Booked" && (
                    <StyledTableCell align="right">
                      {row.scanTime}
                    </StyledTableCell>
                  )}
                {(user?.admin_user_type === "sales" ||
                  user?.admin_user_type === "admin") &&
                  stage === "Doctor Consultation" && (
                    <StyledTableCell align="right">
                      {row.scanTime}
                    </StyledTableCell>
                  )}
                <StyledTableCell align="right">{row.email}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default PatientsTable;
