import { Link, NativeSelect } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../Input/Input.jsx";
import styles from "./Profile.module.css";
import { UpdateProfile } from "../../Services/ProfileService";
import { GetAllUsers, GetUser } from "../../Services/GetUsers.js";
import { GetUserType } from "../../Services/TreatmentService.js";
function Profile({ patient, setAllData, setPatient, isAdmin,onUserUpdateHandler }) {
  const [doctors, setDoctors] = useState([]);
  const [salesPerson, setSalesPerson] = useState([]);
  const [scanningDoctor, setScanningDoctor] = useState([]);
  const [planningDoctor, setPlanningDoctor] = useState([]);
  const [supportPersonnel, setSupportPersonnel] = useState([]);
  useEffect(() => {
    GetUserType("doctor").then((res) => {
      setDoctors(res?.data?.admin_users);
    });
    GetUserType("sales").then((res) => {
      setSalesPerson(res?.data?.admin_users);
    });
    GetUserType("scanning_doctor").then((res) => {
      setScanningDoctor(res?.data?.admin_users);
    });
    GetUserType("planning_doctor").then((res) => {
      setPlanningDoctor(res?.data?.admin_users);
    });
    GetUserType("support").then((res) => {
      setSupportPersonnel(res?.data?.admin_users);
    });
  }, []);
  console.log("Profile User:", isAdmin);
  const updateField = (name, value) => {
    UpdateProfile({
      user_id: patient?.user_id,
      type: name,
      [name]: value,
    }).then((res) => {
      GetAllUsers().then((data) => {
        const allUsers = data.data;
        const updatedUser = allUsers.find(
          (user) => user.user_id === patient.user_id
        );
        setPatient(updatedUser);
        setAllData(allUsers);
      });
    });
  };
  const modifyAssigned = (e, name) => {
    UpdateProfile({
      user_id: patient?.user_id,
      type: name,
      [name]: e.target.value,
    }).then((res) => {
      // GetAllUsers().then((data) => {
      //   const allUsers = data.data;
      //   const updatedUser = allUsers.find(
      //     (user) => user.user_id === patient.user_id
      //   );
      //   setPatient(updatedUser);
      //   setAllData(allUsers);
      // });
      GetUser({ user_id:patient?.user_id }).then((res) => onUserUpdateHandler(res.data));
    });
  };

  return (
    <div className={styles.formContainer}>
      <div className={styles.formBody}>
        <div className={styles.formInpFields}>
          <div className={styles.inputBox}>
            <b>Firstname</b>
            <Input
              value={patient?.firstname}
              name="firstname"
              onSubmit={updateField}
            />
          </div>
          <div className={styles.inputBox}>
            <b>Lastname</b>
            <Input
              value={patient?.lastname}
              name="lastname"
              onSubmit={updateField}
            />
          </div>
          <div className={styles.inputBox}>
            <b>Email</b>
            <Input value={patient?.email} name="email" onSubmit={updateField} />
          </div>
          <div className={styles.inputBox}>
            <b>Contact</b>
            <Input
              value={patient?.contact}
              name="contact"
              onSubmit={updateField}
            />
          </div>
          <div className={styles.inputBox}>
            <b>House No.</b>
            <Input
              value={patient?.address?.house_no}
              name="house-no"
              onSubmit={updateField}
            />
          </div>
          <div className={styles.inputBox}>
            <b>Address Line</b>
            <Input
              value={patient?.address?.address_line_1}
              name="address-line"
              onSubmit={updateField}
              multiline
            />
          </div>
          <div className={styles.inputBox}>
            <b>City</b>
            <Input
              value={patient?.address?.city}
              name="city"
              onSubmit={updateField}
            />
          </div>
          <div className={styles.inputBox}>
            <b>Pincode</b>
            <Input
              value={patient?.address?.pincode}
              name="pincode"
              onSubmit={updateField}
            />
          </div>

          <div className={styles.inputBox}>
            <b>Sales Person Assigned:</b>
            <div style={{ width: "235px", padding: "10px" }}>
              <NativeSelect
                style={{ width: "100%" }}
                onChange={(e) => {
                  modifyAssigned(e, "sales_id");
                }}
                value={patient?.assigned_sales}
                defaultValue={patient?.assigned_sales}
                inputProps={{
                  name: "change sales person",
                  id: "uncontrolled-native1",
                }}
              >
                {patient?.assigned_sales === "" ? (
                  <option value="no-sales">Select Sales Person</option>
                ) : (
                  ""
                )}

                {salesPerson?.map((sales) => (
                  <option value={sales?.admin_user_id}>
                    {sales?.staff_name}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
          <div className={styles.inputBox}>
            <b>Doctor Assigned:</b>
            <div style={{ width: "235px", padding: "10px" }}>
              <NativeSelect
                style={{ width: "100%" }}
                onChange={(e) => {
                  modifyAssigned(e, "doctor_id");
                }}
                value={patient?.assigned_doctor}
                defaultValue={patient?.assigned_doctor}
                inputProps={{
                  name: "change doctor",
                  id: "uncontrolled-native2",
                }}
              >
                {patient?.assigned_doctor === "" ? (
                  <option value="no-doctor">Select Doctor</option>
                ) : (
                  ""
                )}

                {doctors?.map((doctor) => (
                  <option value={doctor?.admin_user_id}>
                    {doctor?.firstname+ " " + doctor?.lastname}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
          <div className={styles.inputBox}>
            <b>Planning Doctor Assigned:</b>
            <div style={{ width: "235px", padding: "10px" }}>
              <NativeSelect
                style={{ width: "100%" }}
                onChange={(e) => {
                  modifyAssigned(e, "planning_doctor");
                }}
                value={patient?.assigned_planning_doctor}
                defaultValue={patient?.assigned_planning_doctor}
                inputProps={{
                  name: "change planning doctor",
                  id: "uncontrolled-native3",
                }}
              >
                {patient?.assigned_planning_doctor === "" ? (
                  <option value="no-planning">Select Planning Doctor</option>
                ) : (
                  ""
                )}

                {planningDoctor?.map((doctor) => (
                  <option value={doctor?.admin_user_id}>
                    {doctor?.staff_name}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
          <div className={styles.inputBox}>
            <b>Scanning Doctor Assigned:</b>
            <div style={{ width: "235px", padding: "10px" }}>
              <NativeSelect
                style={{ width: "100%" }}
                onChange={(e) => {
                  modifyAssigned(e, "scanning_doctor");
                }}
                value={patient?.assigned_scanning_doctor}
                defaultValue={patient?.assigned_scanning_doctor}
                inputProps={{
                  name: "change scanning doctor",
                  id: "uncontrolled-native4",
                }}
                disabled
              >
                {patient?.assigned_scanning_doctor === "" ? (
                  <option value="no-scanning">Select Scanning Doctor</option>
                ) : (
                  ""
                )}

                {scanningDoctor?.map((doctor) => (
                  <option value={doctor?.admin_user_id}>
                    {doctor?.staff_name}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
          <div className={styles.inputBox}>
            <b>Support Personnel Assigned:</b>
            <div style={{ width: "235px", padding: "10px" }}>
              <NativeSelect
                style={{ width: "100%" }}
                onChange={(e) => {
                  modifyAssigned(e, "assigned_support");
                }}
                value={patient?.assigned_support}
                defaultValue={patient?.assigned_support}
                inputProps={{
                  name: "change support personnel",
                  id: "uncontrolled-native3",
                }}
              >
                {patient?.assigned_support === "" ? (
                  <option value="no-assigned_support">Select Support Personnel</option>
                ) : (
                  ""
                )}

                {supportPersonnel?.map((support) => (
                  <option value={support?.admin_user_id}>
                    {support?.staff_name}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
          <Link variant="h6" href={patient?.direct_link} underline="none">
            Direct Login Link{" "}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Profile;
